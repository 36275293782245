import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { useMutation, useQueryClient } from "react-query";

import { ConfirmDialog } from "@/components/ConfirmDialog";
import {
  FitnessEditor,
  useFitnessEditorForm,
} from "@/features/fitnesses/components/FitnessEditor";
import {
  AdminEditor,
  useAdminEditorForm,
} from "@/features/fitnesses/pages/ActiveListPage/AdminEditor";
import {
  FitnessTransactionEditor,
  useFitnessTransactionEditorForm,
} from "@/features/fitnesses/components/FitnessTransactionEditor";
import { createFitness } from "@/services/fitness";
import { getApiErrorMessage, refetchQueries } from "@/utils";

import type { AxiosErrorWithData } from "@/client/api";

const steps = ["ข้อมูลฟิตเนส", "ข้อมูลสำหรับผู้ใช้งาน", "ซื้อ Package"];

export function AddFitnessDialog({
  open: isOpen,
  onClose,
  fetchKeys = [],
}: {
  open: boolean;
  onClose: () => void;
  fetchKeys?: string[];
}) {
  const { enqueueSnackbar } = useSnackbar();
  const ref = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();

  const [isBlocking, setIsBlocking] = useState(false);

  const { mutate: add, isLoading } = useMutation(createFitness, {
    onSuccess: async () => {
      enqueueSnackbar("เพิ่มฟิตเนสสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(getApiErrorMessage(error), { variant: "error" });
    },
  });

  const [activeStep, setActiveStep] = useState(0);

  const showPrevButton = activeStep !== 0;
  const showSubmitButton = activeStep + 1 === steps.length;

  const {
    control: fitnessControl,
    trigger: fitnessTrigger,
    reset: fitnessReset,
    getValues: getFitnessValues,
    formState: fitnessFormState,
  } = useFitnessEditorForm(true);

  const {
    control: adminControl,
    trigger: adminTrigger,
    reset: adminReset,
    getValues: getAdminValues,
  } = useAdminEditorForm();

  const {
    control: transactionControl,
    trigger: transactionTrigger,
    reset: transactionReset,
    getValues: getTransactionValues,
  } = useFitnessTransactionEditorForm();

  const formSteps = [
    <FitnessEditor control={fitnessControl} />,
    <AdminEditor control={adminControl} />,
    <FitnessTransactionEditor control={transactionControl} />,
  ];
  const currentForm = formSteps[activeStep];

  const triggers = [fitnessTrigger, adminTrigger, transactionTrigger];

  async function onClickNext() {
    const isValid = await triggers[activeStep]();
    if (isValid) setActiveStep((prev) => ++prev);
    scrollToTop();
  }

  function onClickPrev() {
    setActiveStep((prev) => --prev);
    scrollToTop();
  }

  function scrollToTop() {
    ref.current?.scrollTo(0, 0);
  }

  async function submit() {
    const isValid = await triggers[activeStep]();

    if (!isValid) return;

    const fitness = getFitnessValues();
    const { email } = getAdminValues();
    const transaction = getTransactionValues();

    const {
      name,
      companyName,
      type,
      isVatRegistration,
      phoneNo,
      mobilePhoneNo,
      faxNo,
      taxNo,
      address,
    } = fitness;

    add({
      name,
      type,
      isVatRegistration: isVatRegistration === "true",
      companyName,
      phoneNo,
      mobilePhoneNo,
      faxNo,
      taxNo,
      address: address.line ?? "",
      postCode: address.postcode ?? "",
      province: address.province ?? "",
      district: address.district ?? "",
      subdistrict: address.subdistrict ?? "",
      email,
      bookingLimit: null,
      fitnessTransaction: {
        price: transaction.price ?? 0,
        discount: transaction.discount ?? 0,
        planId: transaction.plan.id,
        startedAt: transaction.dateRange.start,
        expiredAt: transaction.dateRange.end,
        duration: transaction.duration,
      },
    });
  }

  const isDirty = Object.keys(fitnessFormState.touchedFields).length > 0;

  function close() {
    onClose();
    setActiveStep(0);
    fitnessReset();
    adminReset();
    transactionReset();
  }

  const onCloseDialog = () => (isDirty ? setIsBlocking(true) : close());

  const title = "เพิ่มฟิตเนส";

  return (
    <Dialog open={isOpen} title={title} onClose={onCloseDialog}>
      <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
        {title}
        <Tooltip title="ปิด">
          <IconButton onClick={onCloseDialog} sx={{ ml: "auto" }}>
            <Close />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent ref={ref}>
        <Stepper activeStep={activeStep} sx={{ py: 5 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {currentForm}
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Button color="inherit" variant="contained" onClick={onCloseDialog}>
          ยกเลิก
        </Button>
        <Stack direction="row" gap={2.5}>
          {showPrevButton && (
            <Button color="primary" variant="outlined" onClick={onClickPrev}>
              ย้อนกลับ
            </Button>
          )}
          {showSubmitButton ? (
            <LoadingButton
              loading={isLoading}
              color="primary"
              variant="contained"
              onClick={submit}
            >
              บันทึก
            </LoadingButton>
          ) : (
            <Button color="primary" variant="contained" onClick={onClickNext}>
              ต่อไป
            </Button>
          )}
        </Stack>
      </DialogActions>
      <ConfirmDialog
        title="คุณต้องการยกเลิกหรือไม่"
        open={isBlocking}
        onClose={() => isDirty && (void setIsBlocking(false) || close())}
        onConfirm={() => isDirty && setIsBlocking(false)}
        maxWidth="xs"
        confirmMessage="ไม่ใช่"
        cancelMessage="ใช่"
      />
    </Dialog>
  );
}
