import { TableCell, TableRow } from "@mui/material";

import { TextEllipsis } from "@/components/TextEllipsis";
import { calculateColumnWidth } from "@/utils";

type Package = {
  name: string;
  members: string;
  price: string;
  duration: string;
};

const columnsWidth = [624, 240, 240, 240].map((item) =>
  calculateColumnWidth(item)
);

export type PackageTableRowProps = {
  data: Package;
};

export function PackageTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>ชื่อแพ็กเกจ</TableCell>
      <TableCell width={columnsWidth[1]}>สมาชิกปัจจุบัน</TableCell>
      <TableCell width={columnsWidth[2]}>ราคาต่อเดือน</TableCell>
      <TableCell width={columnsWidth[3]}>ระยะเวลาสัญญา</TableCell>
    </TableRow>
  );
}

export function PackageTableRow({ data }: PackageTableRowProps) {
  const { name, members, price, duration } = data;

  return (
    <TableRow>
      <TableCell>
        <TextEllipsis variant="body2">{name}</TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">{members}</TextEllipsis>
      </TableCell>
      <TableCell>{price}</TableCell>
      <TableCell>{duration}</TableCell>
    </TableRow>
  );
}
