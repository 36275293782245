import { useSearchParams } from "react-router-dom";
import {
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import { useState } from "react";

import { calculateColumnWidth } from "@/utils";
import { formatDate, formatNumber } from "@/formatter";

import type { FitnessTransaction } from "@/models";

const columnsWidth = [140, 195, 195, 195, 195, 140, 140, 80, 64].map((width) =>
  calculateColumnWidth(width)
);

export function FitnessTransactionTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>วันที่ซื้อ</TableCell>
      <TableCell width={columnsWidth[1]}>Subscription Plan</TableCell>
      <TableCell width={columnsWidth[2]}>ราคาต่อเดือน</TableCell>
      <TableCell width={columnsWidth[3]}>ราคารวม</TableCell>
      <TableCell width={columnsWidth[4]}>ระยะเวลาสัญญา</TableCell>
      <TableCell width={columnsWidth[5]}>วันที่เริ่มใช้งาน</TableCell>
      <TableCell width={columnsWidth[6]}>วันที่สิ้นสุด</TableCell>
      <TableCell width={columnsWidth[7]}>สถานะ</TableCell>
      <TableCell width={columnsWidth[8]}></TableCell>
    </TableRow>
  );
}

export type FitnessTransactionTableRowProps = { data: FitnessTransaction };

export function FitnessTransactionTableRow({
  data,
}: FitnessTransactionTableRowProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    id,
    createdAt,
    name,
    price,
    discount,
    duration,
    startedAt,
    expiredAt,
    cancelledAt,
  } = data;

  const totalPrice = price * duration - discount;

  const [status, statusColor] = cancelledAt
    ? ["ยกเลิก", "text.disabled"]
    : ["อนุมัติ", "success.main"];

  const isShowMenu = !cancelledAt;

  function handleClickCancel() {
    searchParams.set("dialog", "cancelSubscription");
    searchParams.set("transactionId", id.toString());
    setSearchParams(searchParams);
    setAnchorEl(null);
  }

  return (
    <TableRow>
      <TableCell>{formatDate(createdAt)}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{formatNumber(price)}</TableCell>
      <TableCell>{formatNumber(totalPrice)}</TableCell>
      <TableCell>{duration} เดือน</TableCell>
      <TableCell>{formatDate(startedAt)}</TableCell>
      <TableCell>{formatDate(expiredAt)}</TableCell>
      <TableCell sx={{ color: statusColor }}>{status}</TableCell>
      <TableCell>
        {isShowMenu && (
          <Tooltip title="เมนู">
            <IconButton
              aria-label="Menu"
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <MoreHoriz />
            </IconButton>
          </Tooltip>
        )}
        <Menu
          open={open}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleClickCancel}>ยกเลิกใบเสร็จ</MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
}
