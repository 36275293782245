import { api } from "@/client/api";
import { configs } from "@/configs";
import { MemberCard } from "@/models";

type GetMemberCards = {
  data: MemberCard[];
};

type UpdateMemberCardInput = Omit<MemberCard, "fitnessCentersId">;

export async function getMemberCards(
  fitnessCenterId: string
): Promise<GetMemberCards> {
  return await api.get(
    `${configs.apiUrl}/commander/member-cards?fitnessCenterId=${fitnessCenterId}`
  );
}

export async function updateMemberCard({
  id,
  ...data
}: UpdateMemberCardInput): Promise<MemberCard> {
  const response = await api.patch(
    `${configs.apiUrl}/commander/member-cards/${id}`,
    data
  );

  return response.data as MemberCard;
}
