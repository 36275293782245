import { Helmet } from "react-helmet-async";
import { Box } from "@mui/material";
import { getDocumentTitle } from "@/utils";
import { DashboardCanvas } from "../components/DashboardCanvas";

export function CommanderSummaryPage() {
  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle("สรุปข้อมูลภาพรวม FitUP")}</title>
      </Helmet>
      <DashboardCanvas title="สรุปข้อมูลภาพรวม FitUP" type="commander" />
    </Box>
  );
}
