import { useState } from "react";
import { FieldValues, useFormState } from "react-hook-form";

import { ConfirmDialog } from "./ConfirmDialog";

import type { ConfirmDialogProps } from "./ConfirmDialog";
import type { Control } from "react-hook-form";

type FormConfirmDialogProps<FieldValue extends FieldValues> =
  ConfirmDialogProps & {
    control: Control<FieldValue>;
  };

export function FormConfirmDialog<FieldValue extends FieldValues>({
  onClose,
  children,
  control,
  ...props
}: FormConfirmDialogProps<FieldValue>) {
  const [isBlocking, setIsBlocking] = useState(false);
  const { touchedFields } = useFormState({ control });
  const isDirty = Object.keys(touchedFields).length > 0;

  return (
    <ConfirmDialog
      {...props}
      onClose={() => (isDirty ? setIsBlocking(true) : onClose?.())}
    >
      {children}
      <ConfirmDialog
        title="คุณต้องการยกเลิกหรือไม่"
        open={isBlocking}
        onCancel={() => isDirty && (void setIsBlocking(false) || onClose?.())}
        onConfirm={() => isDirty && setIsBlocking(false)}
        maxWidth="xs"
        confirmMessage="ไม่ใช่"
        cancelMessage="ใช่"
      />
    </ConfirmDialog>
  );
}
