import { api } from "@/client/api";
import { configs } from "@/configs";

import type { Door } from "@/models";

type CreateDoorInput = Pick<Door, "code" | "name"> & {
  branchId: string;
};

type UpdateDoorInput = CreateDoorInput & { id: number };

type RemoveDoorInput = { id: number; branchId: string };

export async function getDoors(branchId: string) {
  const { data } = await api.get<Door[]>(
    `${configs.apiUrl}/commander/branches/${branchId}/doors`
  );
  return data;
}

export async function createDoor({
  branchId,
  ...data
}: CreateDoorInput): Promise<Door> {
  const response = await api.post(
    `${configs.apiUrl}/commander/branches/${branchId}/doors`,
    data
  );
  return response.data as Door;
}

export async function updateDoor({
  branchId,
  id,
  ...data
}: UpdateDoorInput): Promise<Door> {
  const response = await api.patch(
    `${configs.apiUrl}/commander/branches/${branchId}/doors/${id}`,
    data
  );
  return response.data as Door;
}

export async function removeDoor({
  id,
  branchId,
}: RemoveDoorInput): Promise<void> {
  await api.delete(
    `${configs.apiUrl}/commander/branches/${branchId}/doors/${id}`
  );
}
