import { useController } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import produce from "immer";
import * as yup from "yup";
import { Box, Button, FormHelperText, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

import { upload } from "@/lib/firebase/storage";
import { generateId, useMounted } from "@/utils";
import { isFirebaseError } from "@/lib/firebase/auth";

import { FileDropZone } from "./FileDropZone";
import { ImageUploaderItem } from "./ImageUploaderItem";
import { ImageCropDialog } from "./ImageCropDialog";

import type { ControllerProps, FieldPath } from "react-hook-form";
import type { FieldValues } from "react-hook-form/dist/types/fields";
import type { FileArrayPath } from "@/custom";
import type { InferType } from "yup";
import type { ResponsiveStyleValue } from "@mui/system";
import type { UploadModule } from "@/models";

export type FileForm = InferType<typeof schema>;

type LocalFile = { id: string; file: File; loading: true; url: never };

export const isLocalFile = (form: FileForm | LocalFile): form is LocalFile =>
  !!form.file && !form.url && !form.loading;

type ImageUploaderProps = {
  title?: string;
  fitnessCenterId?: number;
  value: FileForm[];
  onChange: (value: FileForm[]) => void;
  errorMessage?: string;
  module: UploadModule;
  max?: number;
  aspect?: number;
  style?: {
    titleHelperTextFlexDirection?: ResponsiveStyleValue<
      "row" | "row-reverse" | "column" | "column-reverse"
    >;
  };
};

export function ImageUploader({
  title,
  fitnessCenterId,
  value,
  onChange,
  errorMessage = "",
  module,
  max = 1,
  aspect,
  style,
}: ImageUploaderProps) {
  const { enqueueSnackbar } = useSnackbar();

  const mounted = useMounted();
  const [showCropDialog, setShowCropDialog] = useState(false);
  const valueRef = useRef<(FileForm | LocalFile)[]>(value);
  const tasksRef = useRef<Record<string, boolean>>({});
  const isDisabled = max ? value.length >= max : true;

  const cropDialog = {
    title: "ครอบตัดรูปภาพ",
    open: showCropDialog,
    aspect,
    onCancel: () => setShowCropDialog(false),
    onClose: () => setShowCropDialog(false),
    onSubmit: (file: File) => {
      setShowCropDialog(false);
      // Read more: https://immerjs.github.io/immer/produce
      onChange(
        produce(valueRef.current, (draft) => {
          draft.push({
            id: generateId(),
            file,
            loading: true,
          } as LocalFile);
        }) as FileForm[]
      );
    },
  };

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  useEffect(() => {
    value.forEach((item) => {
      if (item.url) {
        return;
      }

      if (tasksRef.current[item.id]) {
        return;
      }

      tasksRef.current[item.id] = true;

      if (!item.file) {
        return;
      }

      const filePath = fitnessCenterId
        ? `/fitnessCenter/${fitnessCenterId}/${module}`
        : module;

      upload(filePath, item.file)
        .then(
          (publicURL) =>
            mounted.current &&
            onChange(
              produce(valueRef.current, (draft) => {
                const index = draft.findIndex((i) => i.id === item.id);

                if (draft[index]) {
                  draft[index].url = publicURL;
                  draft[index].loading = false;
                }
              }) as FileForm[]
            )
        )
        .catch((error) => {
          console.error(error);
          if (isFirebaseError(error)) {
            enqueueSnackbar(error.message, {
              variant: "error",
            });
          }
        });
    });
  }, [value, mounted, onChange, module, fitnessCenterId, enqueueSnackbar]);

  return (
    <Box display="grid" gridTemplateColumns="200px 1fr" gap={3}>
      <Stack gap={2} alignItems="center">
        <FileDropZone
          inputId={module}
          disabled={isDisabled}
          onChange={() => setShowCropDialog(true)}
        />
        <Button
          component="label"
          variant="contained"
          color="primary"
          htmlFor={module}
          size="small"
          sx={{ width: "100px" }}
          disabled={isDisabled}
        >
          เลือกรูป
        </Button>
      </Stack>
      <Box position="relative">
        <Stack
          direction={style?.titleHelperTextFlexDirection ?? "row"}
          gap={1}
          mb={2}
        >
          <Typography variant="body1">{title ?? "อัพโหลดภาพ"}</Typography>
          <FormHelperText>
            (สูงสุด {max} ไฟล์ ขนาดไม่เกิน 1 MB/ไฟล์ นามสกุล JPG, PNG เท่านั้น)
          </FormHelperText>
        </Stack>
        {!errorMessage ? (
          <Stack gap={1.5} height={200} overflow="auto" mb={2}>
            {value.map((field) => (
              <ImageUploaderItem
                key={field.id}
                url={field.url}
                file={field.file}
                loading={field.loading}
                module={module}
                onRemove={() =>
                  onChange(
                    produce(valueRef.current, (draft) => {
                      draft.splice(
                        draft.findIndex((i) => i.id === field.id),
                        1
                      );
                    }) as FileForm[]
                  )
                }
              />
            ))}
          </Stack>
        ) : (
          <FormHelperText error sx={{ height: 200, mb: 2 }}>
            {errorMessage}
          </FormHelperText>
        )}
      </Box>
      <ImageCropDialog {...cropDialog} />
    </Box>
  );
}

export function FormImageUploader<
  TFieldValues extends FieldValues = FieldValues
>({
  control,
  name,
  ...props
}: Pick<ControllerProps<TFieldValues>, "control"> & {
  name: FileArrayPath<TFieldValues>;
} & Pick<
    ImageUploaderProps,
    "module" | "max" | "aspect" | "title" | "style" | "fitnessCenterId"
  >) {
  const { field, fieldState } = useController({
    control,
    name: name as unknown as FieldPath<TFieldValues>,
  });

  return (
    <ImageUploader
      value={field.value}
      onChange={(files) => field.onChange({ target: { value: files } })}
      errorMessage={fieldState.error?.message}
      {...props}
    />
  );
}

const schema = yup.object({
  loading: yup.boolean().isFalse(),
  url: yup.string().required(),
  id: yup.string().required(),
  file: yup.mixed<File>(),
});

FormImageUploader.schema = yup.array().of(schema).default([]);
