import * as Sentry from "@sentry/react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import ErrorOutline from "@mui/icons-material/ErrorOutlined";
import InfoOutline from "@mui/icons-material/InfoOutlined";
import ReportProblemOutlined from "@mui/icons-material/ReportProblemOutlined";
import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { SnackbarProvider } from "notistack";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { QueryClient, QueryClientProvider } from "react-query";

import { theme } from "./theme";
import { AuthenticationProvider } from "./features/authentication/contexts/AuthenticationContext";
import { AuthenticatedGuard } from "./features/authentication/guards/AuthenticatedGuard";
import { NonAuthenticatedGuard } from "./features/authentication/guards/NonAuthenticatedGuard";
import { SignInPage } from "./features/authentication/pages/SignInPage";
import { AppBar } from "./components/AppBar";
import { PackageListPage } from "@/features/packages/pages/PackageListPage";
import { FitnessListPage } from "@/features/fitnesses/pages/FitnessListPage";
import { ActiveListPage } from "@/features/fitnesses/pages/ActiveListPage";
import { ExpiredListPage } from "@/features/fitnesses/pages/ExpiredListPage";
import { FitnessDetailPage } from "@/features/fitnesses/pages/FitnessDetailPage/FitnessDetailPage";
import { BranchList } from "@/features/fitnesses/pages/FitnessDetailPage/BranchList";
import { BranchDetailPage } from "@/features/fitnesses/pages/BranchDetailPage/BranchDetailPage";
import { MemberCardSection } from "@/features/fitnesses/pages/FitnessDetailPage/MemberCardSection";
import { FitnessTransactions } from "@/features/fitnesses/pages/FitnessDetailPage/FitnessTransactionList";
import { BlogListPage } from "@/features/blogs/pages/BlogListPage";
import { BlogDetailPage } from "@/features/blogs/pages/BlogDetailPage/BlogDetailPage";
import { CommanderSummaryPage } from "./features/dashboard/CommanderSummaryPage";
import { PermissionGroupList } from "./features/fitnesses/pages/FitnessDetailPage/PermissionGroupList";
import { DoorList } from "./features/fitnesses/pages/BranchDetailPage/DoorList";
import { PermissionDoorList } from "./features/fitnesses/pages/BranchDetailPage/PermissionDoorList";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export function App() {
  return (
    <Sentry.ErrorBoundary>
      <HelmetProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              preventDuplicate
              iconVariant={{
                success: <CheckCircleOutline />,
                error: <ErrorOutline />,
                warning: <ReportProblemOutlined />,
                info: <InfoOutline />,
              }}
              style={{ whiteSpace: "pre-line" }}
            >
              <QueryClientProvider client={queryClient}>
                <AuthenticationProvider>
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <SentryRoutes>
                      <Route
                        path="/auth/sign-in"
                        element={
                          <NonAuthenticatedGuard>
                            <SignInPage />
                          </NonAuthenticatedGuard>
                        }
                      />
                      <Route
                        path=""
                        element={
                          <AuthenticatedGuard>
                            <AppBar />
                          </AuthenticatedGuard>
                        }
                      >
                        <Route
                          path="/"
                          element={<Navigate to="/dashboards" replace />}
                        />
                        <Route
                          path="*"
                          element={<Navigate to="/dashboards" replace />}
                        />
                        <Route
                          path="dashboards"
                          element={<CommanderSummaryPage />}
                        />
                        <Route path="fitnesses" element={<FitnessListPage />}>
                          <Route index element={<ActiveListPage />} />
                          <Route path="expired" element={<ExpiredListPage />} />
                        </Route>
                        <Route path="fitnesses/:id">
                          <Route element={<FitnessDetailPage />}>
                            <Route index element={<BranchList />} />
                            <Route
                              path="member-card"
                              element={<MemberCardSection />}
                            />
                            <Route
                              path="transactions"
                              element={<FitnessTransactions />}
                            />
                            <Route
                              path="permission-group"
                              element={<PermissionGroupList />}
                            />
                          </Route>
                          <Route
                            path="branches/:branchId"
                            element={<BranchDetailPage />}
                          >
                            <Route index element={<DoorList />} />
                            <Route
                              path="permission-doors"
                              element={<PermissionDoorList />}
                            />
                          </Route>
                        </Route>
                        <Route path="fitnesses/expired/:id">
                          <Route element={<FitnessDetailPage />}>
                            <Route index element={<BranchList />} />
                            <Route
                              path="member-card"
                              element={<MemberCardSection />}
                            />
                            <Route
                              path="transactions"
                              element={<FitnessTransactions />}
                            />
                            <Route
                              path="permission-group"
                              element={<PermissionGroupList />}
                            />
                          </Route>
                          <Route
                            path="branches/:branchId"
                            element={<BranchDetailPage />}
                          >
                            <Route index element={<DoorList />} />
                            <Route
                              path="permission-doors"
                              element={<PermissionDoorList />}
                            />
                          </Route>
                        </Route>
                        <Route path="packages" element={<PackageListPage />} />
                        <Route path="blogs">
                          <Route index element={<BlogListPage />} />
                          <Route path=":id" element={<BlogDetailPage />} />
                        </Route>
                      </Route>
                    </SentryRoutes>
                  </LocalizationProvider>
                </AuthenticationProvider>
              </QueryClientProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
    </Sentry.ErrorBoundary>
  );
}
