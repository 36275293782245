import { useController } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { refetchQueries } from "@/utils";
import { useSnackbar } from "notistack";

import { createBlog } from "@/services/blog";
import { Language } from "@/models";
import { configs } from "@/configs";

import {
  BlogEditorDialog,
  useBlogEditorForm,
} from "../../components/BlogEditorDialog";

import type { AxiosErrorWithData } from "@/client/api";

type AddBlogDialogProps = {
  open: boolean;
  onClose: () => void;
  fetchKeys?: string[];
};

export function AddBlogDialog({
  open: isOpen,
  onClose,
  fetchKeys = [],
}: AddBlogDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useBlogEditorForm();
  const {
    field: { onChange: onChangeLanguage },
  } = useController({ name: "language", control });

  const queryClient = useQueryClient();

  const { mutate: add, isLoading } = useMutation(createBlog, {
    onSuccess: async () => {
      enqueueSnackbar("สร้างบทความสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  const title = "สร้างบทความ";

  function close() {
    reset();
    onClose();
  }

  const onSubmit = handleSubmit(
    (form) => {
      const {
        data: { th, en },
      } = form;

      add({
        thumbnailUrl: form.images[0].url,
        slug: form.slug,
        titleTH: th.title,
        shortDescriptionTH: th.shortDescription,
        contentTH: th.content,
        titleEN: en?.title ?? th.title,
        shortDescriptionEN: en?.shortDescription ?? th.shortDescription,
        contentEN: en?.content ?? th.content,
      });
    },
    (errors) => {
      const { data } = errors;

      const isContentTHError =
        !!data?.th?.title?.message ||
        !!data?.th?.content?.message ||
        !!data?.th?.shortDescription?.message;

      if (isContentTHError) {
        onChangeLanguage(Language.TH);
      }
    }
  );

  return (
    <BlogEditorDialog
      open={isOpen}
      title={title}
      onClose={close}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
