import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";

import { ConfirmDialog } from "@/components/ConfirmDialog";
import { refetchQueries } from "@/utils";
import { removeDoor } from "@/services/door";
import { configs } from "@/configs";

import type { AxiosErrorWithData } from "@/client/api";
import type { Door } from "@/models";

export type RemoveDoorDialogProps = {
  branchId: string;
  open: boolean;
  onClose: () => void;
  data?: Door;
  fetchKeys?: string[];
};

export function RemoveDoorDialog({
  branchId,
  open: isOpen,
  onClose,
  data,
  fetchKeys = [],
}: RemoveDoorDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: remove, isLoading } = useMutation(removeDoor, {
    onSuccess: async () => {
      enqueueSnackbar("ลบประตูสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      onClose();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  if (!data) return <></>;

  const { id, name } = data;

  const onConfirm = () => remove({ id, branchId });

  const title = `ยืนยันการลบประตู ${name}`;

  return (
    <ConfirmDialog
      maxWidth="xs"
      open={isOpen}
      onClose={() => onClose()}
      onConfirm={onConfirm}
      title={title}
      confirmMessage="ยืนยัน"
      loading={isLoading}
    />
  );
}
