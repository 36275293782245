import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { useEffect } from "react";

import { refetchQueries } from "@/utils";
import { configs } from "@/configs";
import { updateDoor } from "@/services/door";

import {
  DoorEditorDialog,
  useDoorEditorForm,
} from "../../components/DoorEditorDialog";

import type { Door } from "@/models";
import type { AxiosErrorWithData } from "@/client/api";

export type EditDoorDialogProps = {
  branchId: string;
  open: boolean;
  data?: Door;
  onClose: () => void;
  fetchKeys?: string[];
};

export function EditDoorDialog({
  branchId,
  open: isOpen,
  onClose,
  data,
  fetchKeys = [],
}: EditDoorDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useDoorEditorForm();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isOpen && data) {
      reset({
        code: data.code,
        name: data.name,
      });
    }
  }, [isOpen, data, reset]);

  const { mutate: update, isLoading } = useMutation(updateDoor, {
    onSuccess: async () => {
      enqueueSnackbar("แก้ไขประตูสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      onClose();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  const onSubmit = handleSubmit((form) => {
    if (!data) return;

    update({
      branchId,
      id: data.id,
      code: form.code,
      name: form.name,
    });
  });

  const title = "แก้ไขประตู";

  return (
    <DoorEditorDialog
      isEdit
      open={isOpen}
      title={title}
      onClose={onClose}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
