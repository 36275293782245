import { useState } from "react";
import { MenuItem, Select } from "@mui/material";

import type { Editor } from "@tiptap/react";
import type { SelectChangeEvent } from "@mui/material";

type FontFamilySelectProps = {
  editor: Editor | null;
};

export function FontFamilySelect({ editor }: FontFamilySelectProps) {
  const [fontFamily, setFontFamily] = useState("Prompt");
  function onChangeFont(event: SelectChangeEvent) {
    if (!editor) return;

    const font = event.target.value;
    editor.chain().focus().setFontFamily(font).run();
    setFontFamily(font);
  }

  return (
    <Select
      value={fontFamily}
      onChange={onChangeFont}
      sx={{
        width: 240,
        borderRadius: 0,
      }}
    >
      <MenuItem value="Prompt">Prompt</MenuItem>
      <MenuItem value="sans-serif">Sans Serif</MenuItem>
    </Select>
  );
}
