import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Helmet } from "react-helmet-async";
import { useState } from "react";

import { MediaViewer } from "@/components/MediaViewer";
import { DataSection } from "@/components/DataSection";
import { formatDate } from "@/formatter";
import { getDocumentTitle } from "@/utils";
import { Language } from "@/models";

import { BlogStatusSwitch } from "./BlogStatusSwitch";
import { RemoveBlogDialog } from "../../components/RemoveBlogDialog";
import { EditBlogDialog } from "../../components/EditBlogDialob";

import type { Blog } from "@/models";
import type { TabsProps } from "@mui/material";
import type { RemoveBlogDialogProps } from "../../components/RemoveBlogDialog";

export type BlogDetailProps = {
  data: Blog;
  fetchKeys?: string[];
};

export function BlogDetail({ data, fetchKeys = [] }: BlogDetailProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tap, setTap] = useState(Language.TH);

  const { id, titleTH, createdAt, thumbnailUrl, slug } = data;

  const onClickEdit: JSX.IntrinsicElements["button"]["onClick"] = (event) => {
    event.stopPropagation();
    searchParams.set("dialog", "blog-edit");
    setSearchParams(searchParams);
  };

  const onClickRemove: JSX.IntrinsicElements["button"]["onClick"] = (event) => {
    event.stopPropagation();
    searchParams.set("dialog", "blog-remove");
    setSearchParams(searchParams);
  };

  const dialog = searchParams.get("dialog");
  const navigate = useNavigate();

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams);
  }

  const editDialog = {
    open: typeof data !== "undefined" && dialog === "blog-edit",
    id: id.toString(),
    onClose: onCloseDialog,
    data,
    fetchKeys,
  };

  const removeDialog: RemoveBlogDialogProps = {
    open: dialog === "blog-remove",
    id: id.toString(),
    onClose(reason) {
      onCloseDialog();
      if (reason === "success") {
        navigate("..");
      }
    },
    fetchKeys,
  };

  const mediaList = [{ url: thumbnailUrl }];
  const slugColumns = [
    [
      {
        title: "Slug",
        data: [{ label: "ชื่อ Slug", value: slug }],
      },
    ],
  ];

  const onChangeTap: TabsProps["onChange"] = (_, value: Language) => {
    setTap(value);
  };

  const dataMapper = {
    [Language.TH]: {
      title: titleTH,
      shortDescription: data.shortDescriptionTH,
      content: data.contentTH,
    },
    [Language.EN]: {
      title: data.titleEN,
      shortDescription: data.shortDescriptionEN,
      content: data.contentEN,
    },
  };

  const tabMapper = {
    [Language.TH]: (
      <BlogDescription
        key={Language.TH}
        data={dataMapper[Language.TH]}
        language={Language.TH}
      />
    ),
    [Language.EN]: (
      <BlogDescription
        key={Language.EN}
        data={dataMapper[Language.EN]}
        language={Language.EN}
      />
    ),
  };

  return (
    <Stack gap={3}>
      <Helmet>
        <title>{getDocumentTitle(titleTH)}</title>
      </Helmet>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap={2} alignItems="center">
          <Typography variant="h6">วันที่สร้าง</Typography>
          <Typography variant="body1" color="text.secondary">
            {formatDate(createdAt)}
          </Typography>
        </Stack>
        <Stack direction="row">
          <BlogStatusSwitch data={data} fetchKeys={fetchKeys} />
          <Tooltip title="แก้ไข">
            <IconButton onClick={onClickEdit}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="ลบ">
            <IconButton onClick={onClickRemove}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
      <MediaViewer mediaList={mediaList} imageWidth={384} imageHeight={216} />
      <DataSection columns={slugColumns} />
      <Divider />
      <Tabs value={tap} onChange={onChangeTap}>
        <Tab label="ไทย" value={Language.TH} sx={{ width: 180 }} />
        <Tab label="ENG" value={Language.EN} sx={{ width: 180 }} />
      </Tabs>
      {tabMapper[tap]}
      <EditBlogDialog {...editDialog} />
      <RemoveBlogDialog {...removeDialog} />
    </Stack>
  );
}

type BlogDescriptionProps = {
  data: { title: string; shortDescription: string; content: string };
  language: Language;
};

function BlogDescription({ data, language }: BlogDescriptionProps) {
  const columns = [
    [
      {
        title: "หัวข้อบทความ",
        data: [{ label: "ชื่อหัวข้อ", value: data.title }],
      },
      {
        title: "รายละเอียด",
        data: [
          { label: "รายละเอียดย่อ", value: data.shortDescription },
          {
            label: "รายละเอียดเต็ม",
            key: `${language}-${data.title}`,
            value: <Box dangerouslySetInnerHTML={{ __html: data.content }} />,
          },
        ],
      },
    ],
  ];

  return <DataSection columns={columns} />;
}
