import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Divider, MenuItem, Stack } from "@mui/material";

import { FormImageUploader } from "@/components/ImageUploader";
import { AddressEditor } from "@/components/AddressEditor";
import { Section } from "@/components/Section";
import { FormSelect } from "@/components/Select";
import { formatFitnessType, formatFitnessVatRegistration } from "@/formatter";
import { FormTextField } from "@/components/FormTextField";
import { configs } from "@/configs";
import { FitnessType } from "@/models";

import type { InferType } from "yup";
import type { ControllerProps } from "react-hook-form";
import type { FileForm } from "@/components/ImageUploader";

export type FitnessEditorState = InferType<typeof schema>;

type FitnessEditorProps = Pick<
  ControllerProps<FitnessEditorState>,
  "control"
> & { fitnessCenterId?: number };

export function FitnessEditor({
  control,
  fitnessCenterId,
}: FitnessEditorProps) {
  return (
    <Stack gap={5}>
      {fitnessCenterId && (
        <>
          <FormImageUploader
            fitnessCenterId={fitnessCenterId}
            control={control}
            name="images"
            module="fitness"
            max={1}
            aspect={configs.aspectRatio.logo}
          />
          <Divider />
        </>
      )}
      <Section columns={2} label="ข้อมูลธุรกิจ">
        <FormSelect name="type" label="ประเภทธุรกิจ" control={control} required>
          {[
            FitnessType.Company,
            FitnessType.LimitedPartnership,
            FitnessType.NaturalPerson,
          ].map((type) => (
            <MenuItem key={type} value={type}>
              {formatFitnessType(type)}
            </MenuItem>
          ))}
        </FormSelect>
        <FormSelect
          name="isVatRegistration"
          label="จดภาษีมูลค่าเพิ่ม"
          control={control}
          required
        >
          {["true", "false"].map((isVatRegistration) => (
            <MenuItem key={isVatRegistration} value={isVatRegistration}>
              {formatFitnessVatRegistration(isVatRegistration === "true")}
            </MenuItem>
          ))}
        </FormSelect>
        <FormTextField
          name="name"
          control={control}
          label="ชื่อบริษัท"
          required
          sx={{ gridColumn: "1/-1" }}
        />
      </Section>
      <Section columns={2} label="ข้อมูลผู้ติดต่อ">
        <FormTextField name="phoneNo" control={control} label="เบอร์สำนักงาน" />
        <FormTextField
          name="mobilePhoneNo"
          control={control}
          label="เบอร์มือถือ"
        />
        <FormTextField name="faxNo" control={control} label="เบอร์โทรสาร" />
      </Section>
      <Section columns={2} label="รายละเอียดธุรกิจ">
        <FormTextField
          name="companyName"
          control={control}
          label="ชื่อธุรกิจ"
          required
        />
        <FormTextField
          name="taxNo"
          control={control}
          label="เลขประจำตัวผู้เสียภาษี"
          required
        />
        <AddressEditor gridColumn="1/-1" name="address" control={control} />
      </Section>
    </Stack>
  );
}

const schema = yup.object({
  images: FormImageUploader.schema.label("รูปภาพ").min(1),
  name: yup.string().label("ชื่อบริษัท").required(),
  type: yup
    .string()
    .oneOf(Object.values(FitnessType))
    .label("ประเภทธุรกิจ")
    .required(),
  isVatRegistration: yup.string().label("จดภาษีมูลค่าเพิ่ม").required(),
  companyName: yup.string().label("ชื่อธุรกิจ").required(),
  phoneNo: yup.string().nullable(),
  mobilePhoneNo: yup.string().nullable(),
  faxNo: yup.string().nullable(),
  taxNo: yup.string().label("เลขประจำตัวผู้เสียภาษี").required(),
  address: AddressEditor.schema,
});

export function useFitnessEditorForm(isEdit = false) {
  const newSchema = isEdit
    ? schema.concat(
        yup.object({
          images: FormImageUploader.schema,
        })
      )
    : schema;

  const resolver = yupResolver(newSchema);

  return useForm({
    resolver,
    defaultValues,
  });
}
const defaultValues = {
  name: "",
  type: FitnessType.Company,
  isVatRegistration: "true",
  images: [] as FileForm[],
} as FitnessEditorState;
