import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";

import { configs } from "@/configs";
import { refetchQueries } from "@/utils";
import { updateMemberCard } from "@/services/memberCard";

import { useMemberCardEditorForm } from "./MemberCardEditor";
import { MemberCardEditorDialog } from "./MemberCardEditorDialog";

import type { MemberCard } from "@/models";

export function EditMemberCardDialog({
  data,
  open: isOpen,
  onClose,
  fetchKeys = [],
}: {
  data: MemberCard;
  open: boolean;
  onClose: () => void;
  fetchKeys?: string[];
}) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { control, handleSubmit, reset } = useMemberCardEditorForm();

  useEffect(() => {
    if (isOpen && data) {
      const {
        title,
        description,
        textColor,
        backgroundColor,
        logoUrl,
        backgroundUrl,
      } = data;
      const logoImg = logoUrl ? [{ id: logoUrl, url: logoUrl }] : [];
      const backgroundImg = backgroundUrl
        ? [{ id: backgroundUrl, url: backgroundUrl }]
        : [];

      reset({
        title,
        description: description ?? "",
        textColor,
        backgroundColor,
        logoImg,
        backgroundImg,
      });
    }
  }, [data, isOpen, reset]);

  const { mutate: update, isLoading } = useMutation(updateMemberCard, {
    onSuccess: async () => {
      enqueueSnackbar("แก้ไขบัตรสมาชิกสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      onClose();
    },
    onError: () => {
      enqueueSnackbar(configs.unknownErrorMessage, { variant: "error" });
    },
  });

  const onSubmit = handleSubmit((form) => {
    const input = {
      id: data.id,
      title: form.title,
      description: form.description ?? "",
      textColor: form.textColor,
      backgroundColor: form.backgroundColor,
      logoUrl: form.logoImg[0].url,
      backgroundUrl: form.backgroundImg[0]?.url ?? null,
    };

    update(input);
  });

  const title = "แก้ไขบัตรสมาชิก";

  return (
    <MemberCardEditorDialog
      fitnessCenterId={data.fitnessCentersId}
      control={control}
      open={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      loading={isLoading}
      title={title}
    />
  );
}
