import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useForm } from "react-hook-form";

import { FormTextField } from "@/components/FormTextField";

import type { ControllerProps } from "react-hook-form";

export type AdminEditorState = yup.InferType<typeof schema>;

type AdminEditorProps = Pick<ControllerProps<AdminEditorState>, "control">;

export function AdminEditor({ control }: AdminEditorProps) {
  return (
    <FormTextField
      fullWidth
      name="email"
      control={control}
      label="อีเมล"
      required
    />
  );
}

export function useAdminEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  email: yup.string().label("อีเมล").email().required(),
});

const resolver = yupResolver(schema);

const defaultValues = {} as AdminEditorState;
