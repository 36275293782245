import { IconButton, Stack, TableCell, TableRow, Tooltip } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { calculateColumnWidth } from "@/utils";
import { formatNumber } from "@/formatter";

import type { Door } from "@/models";
import type { MouseEventHandler } from "react";

const columnsWidth = [240, 496, 496, 112].map((width) =>
  calculateColumnWidth(width)
);

export function DoorTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>หมายเลขประตู</TableCell>
      <TableCell width={columnsWidth[1]}>ชื่อประตู</TableCell>
      <TableCell width={columnsWidth[2]}>จำนวนสิทธิ์การใช้งาน</TableCell>
      <TableCell width={columnsWidth[3]} />
    </TableRow>
  );
}

export type DoorTableRowProps = { data: Door };

export function DoorTableRow({ data }: DoorTableRowProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { id, code, name, permissionCount } = data;

  const openDialog = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    dialogName: string
  ) => {
    event.stopPropagation();
    searchParams.set("dialog", dialogName);
    searchParams.set("id", id.toString());
    setSearchParams(searchParams);
  };

  const onClickEdit: MouseEventHandler<HTMLButtonElement> = (event) =>
    openDialog(event, "door-edit");

  const onClickRemove: MouseEventHandler<HTMLButtonElement> = (event) =>
    openDialog(event, "door-remove");

  return (
    <TableRow hover>
      <TableCell>{code}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{formatNumber(permissionCount)}</TableCell>
      <TableCell>
        <Stack direction="row">
          <Tooltip title="แก้ไข">
            <IconButton onClick={onClickEdit}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="ลบ">
            <IconButton onClick={onClickRemove}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </TableCell>
    </TableRow>
  );
}
