import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Box } from "@mui/material";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer,
  Reducer,
} from "react";

import { FitUPLogo } from "@/components/FitUPLogo";
import {
  signInWithEmailAndPassword,
  signOut as authSignOut,
  onAuthStateChanged,
  isFirebaseError,
  isAxiosError,
} from "@/lib/firebase/auth";
import {
  ApplicationException,
  TooManyRequestException,
  UserExpiredException,
  UserNoPermissionException,
  UserNotAuthenticatedException,
  UserNotFoundException,
  WrongPasswordException,
} from "@/exceptions";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { api } from "@/client/api";
import { configs } from "@/configs";
import { AuthResponsePayload } from "@/models";
import { parseDateTimeFromPrisma } from "@/utils";
import { QueryClient, useQueryClient } from "react-query";

type User = {
  id: string;
  email: string;
};

type AuthenticationState = {
  isInitialized: boolean;
  user: User | null;
  isAuthenticated: boolean;
  error: Error | null;
  getToken: (() => Promise<string>) | null;
  loading: boolean;
  fitnessCentersId: number | null;
};

const { actions, reducer, getInitialState } = createSlice({
  name: "Authentication",
  reducers: {
    initialize: (state, { payload }: PayloadAction<User | null>) => {
      state.isInitialized = true;
      state.isAuthenticated = payload !== null;
      state.user = payload;
      state.loading = false;
    },
    initializeError: (state, { payload }: PayloadAction<Error>) => {
      state.isInitialized = true;
      state.error = payload;
      state.loading = false;
    },
    signInSuccess: (state, { payload }: PayloadAction<User>) => {
      state.user = payload;
      state.isAuthenticated = true;
      state.error = null;
      state.loading = false;
    },
    signInFail: (state, { payload }: PayloadAction<Error>) => {
      state.user = null;
      state.fitnessCentersId = null;
      state.isAuthenticated = false;
      state.error = payload;
      state.loading = false;
    },
    signOut: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.fitnessCentersId = null;
      state.getToken = null;
      state.loading = false;
    },
    signInRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
  },
  initialState: {
    isInitialized: false,
    user: null,
    fitnessCentersId: null,
    isAuthenticated: false,
    error: null,
    loading: false,
  } as AuthenticationState,
});

type Actions = typeof actions;
type AuthenticationActions = ReturnType<Actions[keyof Actions]>;
type AuthenticationDispatch = Dispatch<AuthenticationActions>;

const AuthenticationContext = createContext<
  readonly [AuthenticationState, AuthenticationDispatch] | null
>(null);

export function AuthenticationProvider({
  children,
}: PropsWithChildren<unknown>) {
  const [state, dispatch] = useReducer(
    reducer as Reducer<AuthenticationState, AuthenticationActions>,
    null,
    getInitialState
  );
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const value = [state, dispatch] as const;

  useEffect(() => {
    if (state.error) {
      const isExpireError = state.error instanceof UserExpiredException;

      if (!isExpireError) {
        console.error(state.error);
        enqueueSnackbar(state.error.message, { variant: "error" });
      }
    }
  }, [state.error, enqueueSnackbar]);

  useEffect(() => {
    return onAuthStateChanged(async (firebaseUser) => {
      if (!firebaseUser) {
        return dispatch(actions.initialize(null));
      }

      try {
        await updateCurrentUser({
          dispatch,
          firebaseUserId: firebaseUser.uid,
          email: firebaseUser.email,
          queryClient,
        });
      } catch (error) {
        if (isAxiosError(error)) {
          if (error.response && error.response.status === 403) {
            if (
              (error.response.data as { code: string; message: string })
                ?.code === "auth/expired"
            ) {
              return dispatch(actions.signInFail(new UserExpiredException()));
            }
            return dispatch(
              actions.signInFail(new UserNoPermissionException())
            );
          }
          if (error.response && error.response.status === 401) {
            return dispatch(
              actions.signInFail(new UserNotAuthenticatedException())
            );
          }
        }
        console.error(error);
        return dispatch(actions.signInFail(error as Error));
      }
    });
  }, [queryClient]);

  useEffect(() => {
    if (
      state.error instanceof UserNoPermissionException ||
      state.error instanceof UserNotAuthenticatedException ||
      state.error instanceof UserExpiredException
    ) {
      navigate("/auth/sign-in", { replace: true });
      void signOut(dispatch, queryClient);
    }
  }, [state.error, navigate, queryClient]);

  return (
    <AuthenticationContext.Provider value={value}>
      {!state.isInitialized ? (
        <Box
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <FitUPLogo
            sx={{ animation: "pulse 0.75s linear infinite" }}
            width={64}
          />
        </Box>
      ) : (
        children
      )}
    </AuthenticationContext.Provider>
  );
}

export function useAuthentication() {
  const context = useContext(AuthenticationContext);

  if (!context) {
    throw new Error(
      "useAuthentication must be used inside <AuthenticationProvider />"
    );
  }

  return context;
}

export function useCurrentUser() {
  const [{ user }] = useAuthentication();

  if (!user) {
    throw new UserNotAuthenticatedException();
  }

  return user;
}

export async function signIn(
  dispatch: AuthenticationDispatch,
  email: string,
  password: string
) {
  dispatch(actions.signInRequest());
  try {
    await signInWithEmailAndPassword(email, password);
  } catch (error) {
    if (!isFirebaseError(error)) {
      console.error(error);
      dispatch(actions.signInFail(error as Error));
      return;
    }
    return signInError(dispatch, error);
  }
}

function signInError(
  dispatch: AuthenticationDispatch,
  error: ApplicationException
) {
  switch (error.code) {
    case "auth/wrong-password": {
      dispatch(actions.signInFail(new WrongPasswordException()));
      break;
    }
    case "auth/user-not-found": {
      dispatch(actions.signInFail(new UserNotFoundException()));
      break;
    }
    case "auth/too-many-requests": {
      dispatch(actions.signInFail(new TooManyRequestException()));
      break;
    }
    case "auth/no-permission": {
      dispatch(actions.signInFail(new UserNoPermissionException()));
      break;
    }
    default: {
      dispatch(actions.signInFail(error));
      break;
    }
  }
}

export async function signOut(
  dispatch: AuthenticationDispatch,
  queryClient: QueryClient
): Promise<void> {
  // Sign out from firebase client
  await authSignOut();
  // Clear all connected cache
  queryClient.clear();
  // Clear current user state
  dispatch(actions.signOut());
}

async function validateSignInUser(): Promise<AuthResponsePayload> {
  const response = await api.post(
    `${configs.apiUrl}/auth/commander-login`,
    null
  );
  return parseDateTimeFromPrisma(response.data) as AuthResponsePayload;
}

export async function updateCurrentUser({
  dispatch,
  firebaseUserId,
  email,
  queryClient,
}: {
  dispatch: AuthenticationDispatch;
  firebaseUserId?: string;
  email: string | null | undefined;
  queryClient: QueryClient;
}) {
  if (!firebaseUserId) {
    await signOut(dispatch, queryClient);
    return;
  }

  await validateSignInUser();

  dispatch(
    actions.initialize({
      id: firebaseUserId,
      email: email ?? "",
    })
  );
}
