import { useController, useForm, useWatch } from "react-hook-form";
import { Divider, Stack, Tab, Tabs, TabsProps } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { FormImageUploader } from "@/components/ImageUploader";
import { FormTextField } from "@/components/FormTextField";
import { Section } from "@/components/Section";
import { FormRichText } from "@/components/RichText/RichText";
import { Language } from "@/models";
import { configs } from "@/configs";

import type { Control } from "react-hook-form";
import type { FileForm } from "@/components/ImageUploader";

export type BlogEditorState = yup.InferType<typeof schema>;

type BlogEditorDialogProps = {
  open: boolean;
  title: string;
  onClose: () => void;
  onSubmit: () => void | PromiseLike<void>;
  loading: boolean;
  control: Control<BlogEditorState>;
};

export function BlogEditorDialog({
  open,
  title,
  onClose,
  onSubmit,
  loading,
  control,
}: BlogEditorDialogProps) {
  const {
    field: { onChange },
  } = useController({ name: "language", control });

  const language = useWatch({ name: "language", control });
  const onChangeTap: TabsProps["onChange"] = (_, value) => {
    onChange(value);
  };

  const tabMapper = {
    [Language.TH]: (
      <BlogDescriptionEditor
        key={Language.TH}
        control={control}
        language={Language.TH}
      />
    ),
    [Language.EN]: (
      <BlogDescriptionEditor
        key={Language.EN}
        control={control}
        language={Language.EN}
      />
    ),
  };

  return (
    <FormConfirmDialog
      control={control}
      title={title}
      loading={loading}
      open={open}
      onClose={onClose}
      onConfirm={onSubmit}
    >
      <Stack gap={5}>
        <FormImageUploader
          control={control}
          name="images"
          module="blog"
          max={1}
          aspect={configs.aspectRatio.blog}
        />
        <Divider />
        <Section columns={1} label="Slug">
          <FormTextField
            name="slug"
            control={control}
            label="ชื่อ Slug"
            required
          />
        </Section>
        <Divider />
        <Tabs value={language} onChange={onChangeTap}>
          <Tab label="ไทย" value={Language.TH} sx={{ width: 180 }} />
          <Tab label="ENG" value={Language.EN} sx={{ width: 180 }} />
        </Tabs>
        {tabMapper[language]}
      </Stack>
    </FormConfirmDialog>
  );
}

type BlogDescriptionEditorProps = {
  control: Control<BlogEditorState>;
  language: Language;
};

function BlogDescriptionEditor({
  control,
  language,
}: BlogDescriptionEditorProps) {
  return (
    <Stack gap={5}>
      <Section columns={1} label="หัวข้อบทความ">
        <FormTextField
          name={`data.${language}.title`}
          control={control}
          label="ชื่อบทความ"
          required
        />
      </Section>
      <Section columns={1} label="รายละเอียดย่อ">
        <FormTextField
          name={`data.${language}.shortDescription`}
          control={control}
          label="ข้อความ"
          required
        />
      </Section>
      <Section columns={1} label="รายละเอียดเต็ม">
        <FormRichText
          placeholder="ข้อความ*"
          name={`data.${language}.content`}
          control={control}
        />
      </Section>
    </Stack>
  );
}

export function useBlogEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  images: FormImageUploader.schema.label("รูปภาพ").min(1),
  language: yup.string().oneOf(Object.values(Language)).required(),
  slug: yup.string().label("ชื่อ Slug").required(),
  data: yup.object({
    th: yup.object({
      title: yup.string().label("ชื่อบทความ").required(),
      shortDescription: yup.string().label("ข้อความ").required(),
      content: yup.string().label("ข้อความ").required(),
    }),
    en: yup.object({
      title: yup.string().label("ชื่อบทความ"),
      shortDescription: yup.string().label("ข้อความ"),
      content: yup.string().label("ข้อความ"),
    }),
  }),
});

const resolver = yupResolver(schema);

const defaultValues = {
  language: Language.TH,
  images: [] as FileForm[],
} as BlogEditorState;
