import { useEffect, useMemo, useState } from "react";
import { useController, useWatch } from "react-hook-form";
import { Box, MenuItem, Stack } from "@mui/material";
import * as yup from "yup";

import { FormTextField } from "@/components/FormTextField";
import { FormSelect } from "@/components/Select";
import subdistricts from "@/data/subdistrict.json";

import type { AddressPath } from "@/custom";
import type { Control } from "react-hook-form";
import type { InferType, StringSchema } from "yup";
import type { FieldValues } from "react-hook-form/dist/types/fields";
import type { StackProps } from "@mui/material";

export type AddressForm = InferType<typeof schema>;

export function AddressEditor<
  TFieldValues extends FieldValues = FieldValues,
  TName extends AddressPath<TFieldValues> = AddressPath<TFieldValues>
>({
  name,
  control: _control,
  disabled = false,
  ...props
}: {
  name: TName;
  control?: Control<TFieldValues>;
  disabled?: boolean;
} & StackProps) {
  // TODO: remove cast type
  const control = _control as unknown as Control<Record<string, AddressForm>>;
  const postcode = useWatch({
    control,
    name: `${name}.postcode`,
  });
  const autofill = useAutofillAddress(postcode);
  const { subdistricts, districts, provinces } = autofill;

  // TODO: remove useController
  const {
    field: { onChange: onChangeDistrict },
  } = useController({ name: `${name}.district`, control });
  const {
    field: { onChange: onChangeSubdistrict },
  } = useController({ name: `${name}.subdistrict`, control });
  const {
    field: { onChange: onChangeProvince },
  } = useController({ name: `${name}.province`, control });

  useEffect(() => {
    if (!postcode || postcode.length < 5) {
      onChangeDistrict("");
      onChangeSubdistrict("");
      onChangeProvince("");
      return;
    }
    if (subdistricts.length === 1) {
      onChangeSubdistrict(subdistricts[0]);
    }
    if (districts.length === 1) {
      onChangeDistrict(districts[0]);
    }
    if (provinces.length === 1) {
      onChangeProvince(provinces[0]);
    }
  }, [
    postcode,
    onChangeProvince,
    onChangeSubdistrict,
    onChangeDistrict,
    districts,
    subdistricts,
    provinces,
  ]);

  return (
    <Stack {...props}>
      <FormTextField
        name={`${name}.line`}
        control={control}
        label="ที่อยู่"
        required
        disabled={disabled}
      />
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2.5} mt={2.5}>
        <FormTextField
          name={`${name}.postcode`}
          control={control}
          label="รหัสไปรษณีย์"
          required
          disabled={disabled}
          inputProps={{ maxLength: 5 }}
        />
        <FormSelect
          id="province"
          name={`${name}.province`}
          control={control}
          label="จังหวัด"
          required
          disabled={provinces.length === 0 || disabled}
        >
          {provinces.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </FormSelect>
        <FormSelect
          id="district"
          name={`${name}.district`}
          control={control}
          label="เขต / อำเภอ"
          required
          disabled={districts.length === 0 || disabled}
        >
          {districts.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </FormSelect>
        <FormSelect
          id="subdistrict"
          name={`${name}.subdistrict`}
          control={control}
          label="แขวง / ตำบล"
          required
          disabled={subdistricts.length === 0 || disabled}
        >
          {subdistricts.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </FormSelect>
      </Box>
    </Stack>
  );
}

function useAutofillAddress(postcode: string | undefined) {
  const [data, setData] = useState<Subdistrict[] | null>(null);

  useEffect(() => {
    if (postcode?.length !== 5) {
      setData(null);
      return;
    }

    let mounted = true;

    // TODO: refactor this 1.1MB static file to import from CDN or some bucket
    const data = subdistricts.data.filter((item) => item.postcode === postcode);
    if (mounted) {
      setData(data);
    }

    return () => void (mounted = false);
  }, [postcode]);

  return useMemo(
    () =>
      (data ?? []).reduce(
        (memo, item) => {
          const subdistricts = memo.subdistricts ?? [];
          const districts = memo.districts ?? [];
          const provinces = memo.provinces ?? [];

          subdistricts.push(item.name);
          if (!districts.includes(item.district)) {
            districts.push(item.district);
          }

          if (!provinces.includes(item.province)) {
            provinces.push(item.province);
          }

          return {
            subdistricts,
            districts,
            provinces,
          };
        },
        { subdistricts: [], districts: [], provinces: [] } as {
          subdistricts: string[];
          districts: string[];
          provinces: string[];
        }
      ),
    [data]
  );
}

type Subdistrict = {
  name: string;
  postcode: string;
  district: string;
  province: string;
};

const required = yup.string().when("required", {
  is: true,
  then: (schema: StringSchema) => schema.required(),
});

const requiredPostcode = required.concat(
  yup.string().when("postcode", {
    is: (value: string) => /\d{5}/.test(value),
    then: (schema: StringSchema) => schema.required(),
  })
);

const schema = yup.object({
  required: yup.boolean().default(true),
  line: required.label("ที่อยู่"),
  postcode: yup
    .string()
    .label("รหัสไปรษณีย์")
    .max(5)
    .when("required", {
      is: true,
      otherwise: (schema: StringSchema) => schema,
      then: (schema: StringSchema) =>
        schema.required().matches(/\d{5}/, { message: "ระบุตัวเลข 5 ตัว" }),
    }),
  province: requiredPostcode.label("จังหวัด"),
  district: requiredPostcode.label("เขต / อำเภอ"),
  subdistrict: requiredPostcode.label("แขวง / ตำบล"),
});

AddressEditor.schema = schema;
