import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { refetchQueries } from "@/utils";
import { configs } from "@/configs";
import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { Section } from "@/components/Section";
import { updatePermissionDoor } from "@/services/permissionDoor";

import { FormDoorAutocomplete } from "../../components/DoorAutocomplete";

import type { PermissionDoor } from "@/models";
import type { AxiosErrorWithData } from "@/client/api";
import type { InferType } from "yup";

export type PermissionDoorEditorState = InferType<typeof schema>;

export type EditPermissionDoorDialogProps = {
  branchId: string;
  open: boolean;
  data?: PermissionDoor;
  onClose: () => void;
  fetchKeys?: string[];
};

export function EditPermissionDoorDialog({
  branchId,
  open: isOpen,
  onClose,
  data,
  fetchKeys = [],
}: EditPermissionDoorDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = usePermissionDoorEditorForm();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isOpen && data) {
      reset({
        doors: data.doors,
      });
    }
  }, [isOpen, data, reset]);

  const permissionName = data?.name ?? "";

  const { mutate: update, isLoading } = useMutation(updatePermissionDoor, {
    onSuccess: async () => {
      enqueueSnackbar(`แก้ไขประตูสำหรับสิทธิ์${permissionName}สำเร็จ`, {
        variant: "success",
      });
      await refetchQueries({ queryClient, fetchKeys });
      onClose();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  const onSubmit = handleSubmit((form) => {
    if (!data) return;

    update({
      branchId,
      id: data.id,
      doorIds: form.doors?.map(({ id }) => id) ?? [],
    });
  });

  const title = `เลือกประตูสำหรับสิทธิ์${permissionName}`;

  return (
    <FormConfirmDialog
      control={control}
      title={title}
      loading={isLoading}
      open={isOpen}
      onClose={onClose}
      onConfirm={onSubmit}
    >
      <Section columns={1} label="ประตูที่ใช้งานได้">
        <FormDoorAutocomplete
          label="ชื่อประตู"
          branchId={branchId}
          name="doors"
          control={control}
        />
      </Section>
    </FormConfirmDialog>
  );
}

export function usePermissionDoorEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  doors: yup
    .array(
      yup.object({
        id: yup.number().required(),
        name: yup.string().required(),
      })
    )
    .label("ชื่อประตู"),
});

const resolver = yupResolver(schema);

const defaultValues = { name: "" } as PermissionDoorEditorState;
