import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";

import { createBranch } from "@/services/branch";
import { refetchQueries } from "@/utils";
import { configs } from "@/configs";

import { BranchEditorDialog } from "../../components/BranchEditorDialog";
import { useBranchEditorForm } from "../../components/BranchEditor";

import type { AxiosErrorWithData } from "@/client/api";

type AddBranchDialogProps = {
  fitnessCenterId: string;
  open: boolean;
  onClose: () => void;
  fetchKeys?: string[];
};

export function AddBranchDialog({
  fitnessCenterId,
  open: isOpen,
  onClose,
  fetchKeys = [],
}: AddBranchDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useBranchEditorForm();
  const queryClient = useQueryClient();

  const { mutate: add, isLoading } = useMutation(createBranch, {
    onSuccess: async () => {
      enqueueSnackbar("เพิ่มสาขาสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  const title = "เพิ่มสาขา";

  function close() {
    reset();
    onClose();
  }

  const onSubmit = handleSubmit((form) => {
    add({
      fitnessCenterId: +fitnessCenterId,
      code: form.code,
      name: form.name,
      phoneNo: form.phoneNo,
      email: form.email ?? "",
      address: form.address.line ?? "",
      district: form.address.district ?? "",
      subdistrict: form.address.subdistrict ?? "",
      postCode: form.address.postcode ?? "",
      province: form.address.province ?? "",
      googleMapLink: form.googleMapLink ?? "",
    });
  });

  return (
    <BranchEditorDialog
      open={isOpen}
      title={title}
      onClose={close}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
