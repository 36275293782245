import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";

import { updateBlog } from "@/services/blog";
import { configs } from "@/configs";
import { refetchQueries } from "@/utils";
import { Language } from "@/models";

import {
  BlogEditorDialog,
  useBlogEditorForm,
} from "../components/BlogEditorDialog";

import type { Blog } from "@/models";
import type { AxiosErrorWithData } from "@/client/api";

export type EditBlogDialogProps = {
  open: boolean;
  data?: Blog;
  onClose: () => void;
  id: string;
  fetchKeys?: string[];
};

export function EditBlogDialog({
  open: isOpen,
  onClose,
  id,
  data,
  fetchKeys = [],
}: EditBlogDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useBlogEditorForm();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isOpen && data) {
      const { thumbnailUrl, slug } = data;
      reset({
        images: [{ id: thumbnailUrl, url: thumbnailUrl }],
        language: Language.TH,
        slug,
        data: {
          th: {
            title: data.titleTH,
            shortDescription: data.shortDescriptionTH,
            content: data.contentTH,
          },
          en: {
            title: data.titleEN,
            shortDescription: data.shortDescriptionEN,
            content: data.contentEN,
          },
        },
      });
    }
  }, [isOpen, data, reset]);

  function close() {
    onClose();
  }

  const { mutate: update, isLoading } = useMutation(updateBlog, {
    onSuccess: async () => {
      enqueueSnackbar("บันทึกบทความสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  const onSubmit = handleSubmit((form) => {
    const {
      data: { th, en },
    } = form;

    const input = {
      id: +id,
      thumbnailUrl: form.images[0].url,
      slug: form.slug,
      titleTH: th.title,
      shortDescriptionTH: th.shortDescription,
      contentTH: th.content,
      titleEN: en?.title ?? th.title,
      shortDescriptionEN: en?.shortDescription ?? th.shortDescription,
      contentEN: en?.content ?? th.content,
    };

    update(input);
  });

  const title = "แก้ไขบทความ";

  return (
    <BlogEditorDialog
      open={isOpen}
      title={title}
      onClose={close}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
