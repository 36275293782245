import { api } from "@/client/api";
import { configs } from "@/configs";

import type { PermissionGroup } from "@/models";

type CreatePermissionGroupInput = Pick<PermissionGroup, "name" | "type"> & {
  fitnessCenterId: number;
};

type UpdatePermissionGroupInput = Pick<PermissionGroup, "id" | "name"> & {
  fitnessCenterId: number;
};

export async function getPermissionGroups(fitnessCenterId: string) {
  const { data } = await api.get<PermissionGroup[]>(
    `${configs.apiUrl}/commander/fitness/${fitnessCenterId}/permission-groups`
  );
  return data;
}

export async function createPermissionGroup({
  fitnessCenterId,
  ...data
}: CreatePermissionGroupInput): Promise<PermissionGroup> {
  const response = await api.post(
    `${configs.apiUrl}/commander/fitness/${fitnessCenterId}/permission-groups`,
    data
  );
  return response.data as PermissionGroup;
}

export async function updatePermissionGroup({
  fitnessCenterId,
  id,
  ...data
}: UpdatePermissionGroupInput): Promise<PermissionGroup> {
  const response = await api.patch(
    `${configs.apiUrl}/commander/fitness/${fitnessCenterId}/permission-groups/${id}`,
    data
  );
  return response.data as PermissionGroup;
}

export async function removePermissionGroup(id: number): Promise<void> {
  await api.delete(`${configs.apiUrl}/commander/permission-groups/${id}`);
}
