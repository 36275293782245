import {
  Box,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";

import { PackageTableRow, PackageTableRowHeader } from "./PackageTableRow";

export function PackageListPage() {
  const data = [
    {
      name: "ฟรี",
      members: "มากกว่า 1,500 คน",
      price: "0 บาท",
      duration: "-",
    },
    {
      name: "สแตนดาร์ด",
      members: "0 - 100 คน",
      price: "5,000 บาท",
      duration: "12 เดือน",
    },
    {
      name: "พรีเมียม",
      members: "101 - 500 คน",
      price: "10,000 บาท",
      duration: "12 เดือน",
    },
    {
      name: "แอดวานซ์",
      members: "501 - 1,000 คน",
      price: "15,000 บาท",
      duration: "12 เดือน",
    },
    {
      name: "มาสเตอร์",
      members: "1,001 - 1,500 คน",
      price: "20,000 บาท",
      duration: "12 เดือน",
    },
    {
      name: "องค์กร",
      members: "มากกว่า 1,500 คน",
      price: "-",
      duration: "-",
    },
  ];

  return (
    <Box>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <PackageTableRowHeader />
              </TableHead>
              <TableBody>
                {data.map((item) => (
                  <PackageTableRow key={item.name} data={item} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Box>
  );
}
