import { MuiColorInput } from "mui-color-input";
import { useState } from "react";

import type { Editor } from "@tiptap/react";

type RichTextColorPickerProps = {
  editor: Editor | null;
};

export function RichTextColorPicker({ editor }: RichTextColorPickerProps) {
  const [fontColor, setFontColor] = useState("#000000");

  return (
    <MuiColorInput
      format="hex"
      value={fontColor}
      inputProps={{ sx: { width: 82 }, maxLength: 7 }}
      onChange={(color) => {
        editor?.chain().focus().setColor(color).run();
        setFontColor(color);
      }}
    />
  );
}
