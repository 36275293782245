import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { useEffect } from "react";

import { refetchQueries } from "@/utils";
import { configs } from "@/configs";
import { updateBranch } from "@/services/branch";

import { BranchEditorDialog } from "../../components/BranchEditorDialog";
import { useBranchEditorForm } from "../../components/BranchEditor";

import type { Branch } from "@/models";
import type { AxiosErrorWithData } from "@/client/api";

export type EditBranchDialogProps = {
  open: boolean;
  data?: Branch;
  onClose: () => void;
  id: number;
  fetchKeys?: string[];
};

export function EditBranchDialog({
  open: isOpen,
  onClose,
  id,
  data,
  fetchKeys = [],
}: EditBranchDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useBranchEditorForm();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isOpen && data) {
      reset({
        name: data.name,
        code: data.code ?? "",
        phoneNo: data.phoneNo,
        email: data.email ?? "",
        address: {
          line: data.address,
          postcode: data.postCode,
          province: data.province,
          district: data.district,
          subdistrict: data.subdistrict,
        },
        googleMapLink: data.googleMapLink ?? "",
      });
    }
  }, [isOpen, data, reset]);

  const { mutate: update, isLoading } = useMutation(updateBranch, {
    onSuccess: async () => {
      enqueueSnackbar("แก้ไขสาขาสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      onClose();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  const onSubmit = handleSubmit((form) => {
    const input = {
      id,
      code: form.code,
      name: form.name,
      phoneNo: form.phoneNo,
      email: form.email,
      address: form.address.line ?? "",
      district: form.address.district ?? "",
      subdistrict: form.address.subdistrict ?? "",
      postCode: form.address.postcode ?? "",
      province: form.address.province ?? "",
      googleMapLink: form.googleMapLink,
    };

    update(input);
  });

  const title = "แก้ไขสาขา";

  return (
    <BranchEditorDialog
      open={isOpen}
      title={title}
      onClose={onClose}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
