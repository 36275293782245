import { api } from "@/client/api";
import { Fitness, FitnessCenter } from "@/models";
import { configs } from "@/configs";
import { parseDateTimeFromPrisma, parseISOToPrisma } from "@/utils";
import { DateTime } from "luxon";

type UpdateFitnessInput = Partial<FitnessCenter> & { id: number };

type CreateFitnessInput = Omit<
  FitnessCenter,
  "id" | "logoUrl" | "activePlan" | "hasPermissionGroupControl" | "branches"
> & {
  email: string;
  fitnessTransaction: {
    price: number;
    discount: number;
    planId: number;
    startedAt: DateTime;
    expiredAt: DateTime;
    duration: number;
  };
};

type GetFitness = {
  data: Fitness[];
  total: number;
};

type GetExpiredFitness = {
  data: FitnessCenter[];
  total: number;
};

type UpdateFitnessSettingInput = {
  id: number;
  hasPermissionGroupControl: boolean;
  branches: { id: number; hasDoorAccessControl: boolean }[];
};

export async function getFitnessActiveList(query = "") {
  const { data } = await api.get<GetFitness>(
    `${configs.apiUrl}/commander/fitness/active?${query}`
  );
  return parseDateTimeFromPrisma(data) as GetFitness;
}

export async function getFitnessExpiredList(query = "") {
  const { data } = await api.get<GetExpiredFitness>(
    `${configs.apiUrl}/commander/fitness/expired?${query}`
  );
  return parseDateTimeFromPrisma(data) as GetExpiredFitness;
}

export async function getFitness(id: string) {
  const { data } = await api.get<FitnessCenter>(
    `${configs.apiUrl}/commander/fitness/${id}`
  );
  return data;
}

export async function updateFitness({
  id,
  ...data
}: UpdateFitnessInput): Promise<FitnessCenter> {
  const response = await api.patch(
    `${configs.apiUrl}/commander/fitness/${id}`,
    data
  );
  return response.data as FitnessCenter;
}

export async function createFitness(
  data: CreateFitnessInput
): Promise<FitnessCenter> {
  const response = await api.post(
    `${configs.apiUrl}/commander/fitness`,
    parseISOToPrisma(data)
  );
  return response.data as FitnessCenter;
}

export async function updateFitnessSetting({
  id,
  ...data
}: UpdateFitnessSettingInput) {
  await api.patch(`${configs.apiUrl}/commander/fitness/${id}/setting`, data);
}
