import { api } from "@/client/api";
import { configs } from "@/configs";
import { parseDateTimeFromPrisma, parseISOToPrisma } from "@/utils";

import type { FitnessTransaction } from "@/models";

type CreateFitnessTransactionInput = Omit<
  FitnessTransaction,
  "id" | "name" | "maxMember" | "createdAt" | "cancelledAt"
> & { fitnessCenterId: number; planId: number };

type GetFitnessTransaction = {
  data: FitnessTransaction[];
  total: number;
};

export async function getFitnessTransactions(id: string) {
  const { data } = await api.get<GetFitnessTransaction>(
    `${configs.apiUrl}/commander/transactions?fitnessCenterId=${id}`
  );
  return parseDateTimeFromPrisma(data) as GetFitnessTransaction;
}

export async function cancelFitnessTransaction(id: string): Promise<void> {
  await api.patch(`${configs.apiUrl}/commander/transactions/${id}/cancel`);
}

export async function createFitnessTransaction(
  data: CreateFitnessTransactionInput
): Promise<FitnessTransaction> {
  const response = await api.post(
    `${configs.apiUrl}/commander/transactions`,
    parseISOToPrisma(data)
  );
  return response.data as FitnessTransaction;
}
