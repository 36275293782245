import { Typography, TableCell, TableRow, IconButton } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import { TextEllipsis } from "@/components/TextEllipsis";
import { formatDate } from "@/formatter";
import { calculateColumnWidth, mapOptional } from "@/utils";
import { now } from "@/lib/dateTime";
import { Fitness, FitnessSort, SortType } from "@/models";

import type { MouseEventHandler } from "react";
import type { DateTime } from "luxon";

const columnsWidth = [564, 300, 240, 240].map((item) =>
  calculateColumnWidth(item)
);

export type ActiveTableRowProps = {
  data: Fitness;
};

export function ActiveTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>ชื่อฟิตเนส</TableCell>
      <TableCell width={columnsWidth[1]} align="center">
        Package ปัจจุบัน
      </TableCell>
      <TableCell width={columnsWidth[2]} align="center">
        <SortField field={FitnessSort.CreatedAt} />
        วันที่เริ่มใช้งาน
      </TableCell>
      <TableCell width={columnsWidth[3]} align="center">
        <SortField field={FitnessSort.ExpiredAt} />
        วันที่สิ้นสุด
      </TableCell>
    </TableRow>
  );
}

export function ActiveTableRow({ data }: ActiveTableRowProps) {
  const navigate = useNavigate();

  const { fitnessCenter, plan, startedAt, expiredAt } = data;
  const idString = fitnessCenter.id.toString();

  const onClickTableRow: MouseEventHandler<HTMLTableRowElement> = () => {
    navigate(idString);
  };

  return (
    <TableRow hover tabIndex={-1} role="link" onClick={onClickTableRow}>
      <TableCell>
        <TextEllipsis variant="body2">{fitnessCenter.name}</TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2" align="center">
          {plan.name}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2" align="center">
          {formatDate(startedAt)}
        </TextEllipsis>
      </TableCell>
      <TableCell align="center">
        <PackageExpiredDateDisplay date={expiredAt} />
      </TableCell>
    </TableRow>
  );
}

function PackageExpiredDateDisplay({ date }: { date: DateTime }) {
  const currentTime = now();
  const { text, color, isNearExpired } = mapOptional(date, (iso) => {
    const isNearExpired = currentTime.plus({ day: 30 }) > iso;

    const text = formatDate(iso);

    return {
      text,
      color: isNearExpired ? "error.main" : "",
      isNearExpired,
    };
  }) ?? { text: "-", color: "", isNearExpired: false };

  return (
    <TextEllipsis variant="body2" color={color}>
      {text}
      {isNearExpired && (
        <Typography component="span" variant="inherit">
          <br /> (ใกล้หมดอายุ)
        </Typography>
      )}
    </TextEllipsis>
  );
}

function SortField({ field }: { field: FitnessSort }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const sort = searchParams.get("sort") as FitnessSort | undefined;
  const sortType = searchParams.get("sortType") as SortType | undefined;
  const setSort = (s: FitnessSort) => () => {
    if (s) {
      searchParams.set("sort", s);
      searchParams.set(
        "sortType",
        sortType === SortType.Asc && sort === field
          ? SortType.Desc
          : SortType.Asc
      );
    }

    searchParams.delete("page");
    setSearchParams(searchParams);
  };
  return (
    <IconButton
      sx={{ padding: 0, width: "auto", height: "auto" }}
      onClick={setSort(field)}
    >
      {sort === field && sortType === SortType.Asc ? (
        <ArrowDownwardIcon />
      ) : (
        <ArrowUpwardIcon />
      )}
    </IconButton>
  );
}
