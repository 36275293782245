import { Pagination } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { mapOptional } from "@/utils";
import { configs } from "@/configs";

import type { PaginationProps } from "@mui/material";

export type SearchParamsPaginationProps = {
  total?: number;
};

export function SearchParamsPagination({ total }: SearchParamsPaginationProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = parseInt(searchParams.get("page") ?? "1") || 1;
  const onChangePage: PaginationProps["onChange"] = (_, page: number) => {
    if (page === 1) {
      searchParams.delete("page");
    } else {
      searchParams.set("page", page.toString());
    }

    setSearchParams(searchParams);
  };

  const pageCount = mapOptional(total, (t) =>
    Math.ceil(t / configs.limitPerPage)
  );

  return (
    <Pagination
      sx={{ display: "flex", justifyContent: "flex-end" }}
      page={page}
      count={pageCount || 1}
      shape="rounded"
      size="large"
      onChange={onChangePage}
    />
  );
}
