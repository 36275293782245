import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { Section } from "@/components/Section";
import { FormTextField } from "@/components/FormTextField";

import type { Control } from "react-hook-form";
import type { InferType } from "yup";

export type DoorEditorState = InferType<typeof schema>;

type DoorEditorDialogProps = {
  open: boolean;
  title: string;
  onClose: () => void;
  onSubmit: () => void | PromiseLike<void>;
  loading: boolean;
  control: Control<DoorEditorState>;
  isEdit?: boolean;
};

export function DoorEditorDialog({
  open,
  title,
  onClose,
  onSubmit,
  loading,
  control,
}: DoorEditorDialogProps) {
  return (
    <FormConfirmDialog
      control={control}
      title={title}
      loading={loading}
      open={open}
      onClose={onClose}
      onConfirm={onSubmit}
    >
      <Section columns={2}>
        <FormTextField
          name="code"
          control={control}
          label="หมายเลขประตู"
          required
        />
        <FormTextField
          name="name"
          control={control}
          label="ชื่อประตู"
          required
        />
      </Section>
    </FormConfirmDialog>
  );
}

export function useDoorEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  code: yup.string().label("หมายเลขประตู").required(),
  name: yup.string().label("ชื่อประตู").required(),
});

const resolver = yupResolver(schema);

const defaultValues = { name: "" } as DoorEditorState;
