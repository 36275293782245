import {
  Button,
  Card,
  CardContent,
  Stack,
  Table,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { DataTableBody } from "@/components/DataTableBody";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { refetchQueries, useRequireParams } from "@/utils";
import {
  cancelFitnessTransaction,
  getFitnessTransactions,
} from "@/services/fitnessTransation";
import { formatNumber } from "@/formatter";
import { ItemsTotal } from "@/components/ItemsTotal";
import { configs } from "@/configs";

import {
  FitnessTransactionTableRow,
  FitnessTransactionTableRowHeader,
} from "./FitnessTransactionTableRow";
import { SubscribeDialog } from "./SubscribeDialog";
import { GET_FITNESS_QUERY_KEY } from "./FitnessDetailPage";

import type { ConfirmDialogProps } from "@/components/ConfirmDialog";
import type { AxiosErrorWithData } from "@/client/api";

const QUERY_KEY = "getTransactions";

export function FitnessTransactions() {
  const { id } = useRequireParams(["id"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { data: raw, isLoading } = useQuery([QUERY_KEY], () =>
    getFitnessTransactions(id)
  );

  const { mutate: cancel, isLoading: isCancelLoading } = useMutation(
    cancelFitnessTransaction,
    {
      onSuccess: async () => {
        enqueueSnackbar("ยกเลิกการจองสำเร็จ", { variant: "success" });
        await refetchQueries({
          queryClient,
          fetchKeys: [QUERY_KEY, GET_FITNESS_QUERY_KEY],
        });
        onCloseDialog();
      },
      onError: (error: AxiosErrorWithData) => {
        console.error(error);
        enqueueSnackbar(
          error.response?.data.message ?? configs.unknownErrorMessage,
          { variant: "error" }
        );
      },
    }
  );

  const dialog = searchParams.get("dialog");
  const transactionId = searchParams.get("transactionId");
  function add() {
    searchParams.set("dialog", "subscribe-plan");
    setSearchParams(searchParams);
  }

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams, { replace: true });
  }

  const subscribeDialog = {
    fitnessCenterId: id,
    open: dialog === "subscribe-plan",
    onClose: onCloseDialog,
    fetchKeys: [QUERY_KEY, GET_FITNESS_QUERY_KEY],
  };

  const data = raw?.data ?? [];
  const total = raw?.total ?? 0;

  const totalPrice = data.reduce(
    (total, { price, duration, discount, cancelledAt }) =>
      total + (cancelledAt ? 0 : price * duration - discount),
    0
  );

  const confirmCancelDialog: ConfirmDialogProps = {
    maxWidth: "xs",
    confirmMessage: "ใช่",
    cancelMessage: "ไม่ใช่",
    reverse: true,
    onClose: onCloseDialog,
    open: dialog === "cancelSubscription" && !!transactionId,
    loading: isCancelLoading,
    title: "คุณต้องการยกเลิกรายการนี้หรือไม่",
    onConfirm: () => {
      if (!transactionId) return;
      cancel(transactionId);
    },
  };

  return (
    <Card>
      <CardContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Stack direction="row" alignItems="center" gap={3}>
            <Typography variant="h6">ยอดขายรวม</Typography>
            <Typography variant="h4">{formatNumber(totalPrice)}</Typography>
            <Typography variant="h6">บาท</Typography>
          </Stack>
          <Button variant="contained" size="large" onClick={add}>
            Subscription
          </Button>
        </Stack>
        <ItemsTotal count={total} />
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <FitnessTransactionTableRowHeader />
            </TableHead>
            <DataTableBody
              loading={isLoading}
              data={data}
              searchKeys={["query", "status"]}
            >
              {data.map((item) => (
                <FitnessTransactionTableRow key={item.id} data={item} />
              ))}
            </DataTableBody>
          </Table>
        </TableContainer>
        <ConfirmDialog {...confirmCancelDialog} />
        <SubscribeDialog {...subscribeDialog} />
      </CardContent>
    </Card>
  );
}
