import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";

import { refetchQueries } from "@/utils";
import { configs } from "@/configs";
import { createDoor } from "@/services/door";

import {
  DoorEditorDialog,
  useDoorEditorForm,
} from "../../components/DoorEditorDialog";

import type { AxiosErrorWithData } from "@/client/api";

type AddDoorDialogProps = {
  branchId: string;
  open: boolean;
  onClose: () => void;
  fetchKeys?: string[];
};

export function AddDoorDialog({
  branchId,
  open: isOpen,
  onClose,
  fetchKeys = [],
}: AddDoorDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useDoorEditorForm();
  const queryClient = useQueryClient();

  const { mutate: add, isLoading } = useMutation(createDoor, {
    onSuccess: async () => {
      enqueueSnackbar("เพิ่มประตูสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  const title = "เพิ่มประตู";

  function close() {
    reset();
    onClose();
  }

  const onSubmit = handleSubmit((form) => {
    add({
      branchId,
      code: form.code,
      name: form.name,
    });
  });

  return (
    <DoorEditorDialog
      open={isOpen}
      title={title}
      onClose={close}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
