import { parseDateTimeFromPrisma } from "@/utils";
import { api } from "@/client/api";
import { configs } from "@/configs";

import type { FitnessPlan } from "@/models";

export async function getFitnessPlans() {
  const { data } = await api.get<FitnessPlan[]>(
    `${configs.apiUrl}/commander/plans`
  );
  return parseDateTimeFromPrisma(data) as FitnessPlan[];
}
