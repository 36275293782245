import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import type { PermissionDoor } from "@/models";

type PermissionDoorsDialogProps = {
  open: boolean;
  onClose: () => void;
} & Pick<PermissionDoor, "doors">;

export function PermissionDoorsDialog({
  open: isOpen,
  doors,
  onClose,
}: PermissionDoorsDialogProps) {
  const title = "สิทธิ์การใช้งานประตู";
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box display="grid" gridTemplateColumns="120px 1fr" gap={2}>
          <Typography variant="subtitle1" color="text.secondary">
            ประตูที่ใช้งานได้
          </Typography>
          <Typography variant="body1">
            {doors.map(({ id, name }) => (
              <Box key={id} component="li">
                {name}
              </Box>
            ))}
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
