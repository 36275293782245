import YouTubeIcon from "@mui/icons-material/YouTube";
import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useForm } from "react-hook-form";

import { FormTextField } from "@/components/FormTextField";
import { FormNumericTextField } from "@/components/NumericTextField";
import { youtubeLink } from "@/lib/yup/validation";

import { MenuButton } from "./MenuButton";

import type { Editor } from "@tiptap/react";

type YoutubeMenuEditorState = yup.InferType<typeof schema>;

type YoutubeMenuProps = {
  editor: Editor | null;
};

export function YoutubeMenu({ editor }: YoutubeMenuProps) {
  const [open, setOpen] = useState(false);
  const { control, handleSubmit, reset } = useYoutubeMenuEditorForm();

  const openDialog = () => setOpen(true);
  const close = () => {
    setOpen(false);
    reset();
  };

  const title = "Youtube URL";

  const onSubmit = handleSubmit(({ url, width, height }) => {
    if (!editor) return;

    editor.commands.setYoutubeVideo({ src: url, width, height });
    close();
  });

  return (
    <>
      <MenuButton onClick={openDialog} Icon={YouTubeIcon} />
      <Dialog maxWidth="sm" open={open}>
        <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
          {title}
        </DialogTitle>
        <DialogContent>
          <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
            <FormTextField
              name="url"
              control={control}
              placeholder="https://www.youtube.com/watch?v=DoGR6L-Viq8"
              label="Youtube URL"
              sx={{ gridColumn: "1/-1" }}
            />
            <FormNumericTextField
              name="width"
              control={control}
              label="width"
            />
            <FormNumericTextField
              name="height"
              control={control}
              label="height"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" variant="contained" onClick={close}>
            ยกเลิก
          </Button>
          <Button color="primary" variant="contained" onClick={onSubmit}>
            ตกลง
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function useYoutubeMenuEditorForm() {
  return useForm({
    resolver,
    defaultValues: {
      url: "",
      width: 640,
      height: 480,
    } as YoutubeMenuEditorState,
  });
}

const schema = yup.object({
  url: youtubeLink.required(),
  width: yup.number().required().min(320),
  height: yup.number().required().min(180),
});

const resolver = yupResolver(schema);
