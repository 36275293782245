import { FormControlLabel, Switch as MuiSwitch } from "@mui/material";
import { useController } from "react-hook-form";

import type { SwitchProps as MuiSwitchProps } from "@mui/material";
import type { ControllerProps, FieldPath, FieldValues } from "react-hook-form";

export type SwitchProps = MuiSwitchProps & {
  label?: string;
};

export function Switch({ label, ...props }: SwitchProps) {
  return <FormControlLabel control={<MuiSwitch {...props} />} label={label} />;
}

export function FormSwitch<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  ...props
}: Pick<ControllerProps<TFieldValues, TName>, "name" | "control"> &
  SwitchProps) {
  const { field } = useController({
    name,
    control,
  });

  return (
    <Switch
      onChange={field.onChange}
      onBlur={field.onBlur}
      checked={field.value}
      {...props}
    />
  );
}
