import { useState } from "react";
import { MenuItem, Select } from "@mui/material";

import type { Editor } from "@tiptap/react";
import type { SelectChangeEvent } from "@mui/material";
import type { Level } from "@tiptap/extension-heading";

type HeadingSelectProps = {
  editor: Editor | null;
};

export function HeadingSelect({ editor }: HeadingSelectProps) {
  const [heading, setHeading] = useState<number>(0);

  function onChangeHeading(event: SelectChangeEvent<number>) {
    if (!editor) return;

    const headingLevel = event.target.value as Level;
    editor.chain().focus().toggleHeading({ level: headingLevel }).run();
    setHeading(headingLevel);
  }

  const headings = Array.from({ length: 5 }, (_, i) => i + 2);

  return (
    <Select value={heading} onChange={onChangeHeading} sx={{ borderRadius: 0 }}>
      <MenuItem value={0}>Paragraph</MenuItem>
      {headings.map((heading) => (
        <MenuItem key={heading} value={heading}>
          H{heading}
        </MenuItem>
      ))}
    </Select>
  );
}
