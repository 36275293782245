import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useController, useForm, useWatch } from "react-hook-form";
import { useEffect } from "react";

import { Section } from "@/components/Section";
import {
  FitnessPlanAutocomplete,
  FormFitnessPlanAutocomplete,
} from "@/components/FitnessPlanAutocomplete";
import {
  FormNumericTextField,
  NumericTextField,
} from "@/components/NumericTextField";
import { FormDatePicker } from "@/components/DatePicker";
import { datetime } from "@/lib/yup";

import type { ControllerProps } from "react-hook-form";

export type FitnessTransactionEditorState = yup.InferType<typeof schema>;

type FitnessTransactionEditorProps = Pick<
  ControllerProps<FitnessTransactionEditorState>,
  "control"
>;

export function FitnessTransactionEditor({
  control,
}: FitnessTransactionEditorProps) {
  const plan = useWatch({ name: "plan", control });
  const duration = useWatch({ name: "duration", control });
  const price = useWatch({ name: "price", control });
  const discount = useWatch({ name: "discount", control });
  const start = useWatch({ name: "dateRange.start", control });

  const total = (price ?? 0) * (duration ?? 0) - (discount ?? 0);

  const {
    field: { onChange },
  } = useController({ name: "price", control });
  const {
    field: { onChange: onChangeEnd },
  } = useController({ name: "dateRange.end", control });

  useEffect(() => {
    const planPrice = plan?.price ?? 0;
    onChange(planPrice);
  }, [onChange, plan]);

  useEffect(() => {
    const end = start?.plus({ month: duration ?? 0, day: duration ? -1 : 0 });
    onChangeEnd(end);
  }, [duration, onChangeEnd, start]);

  return (
    <Section columns={4} label="">
      <FormFitnessPlanAutocomplete
        name="plan"
        control={control}
        label="Subscription Plan"
        required
        sx={{ gridColumn: "1/span 2" }}
      />
      <FormNumericTextField
        name="duration"
        control={control}
        label="ระยะเวลาสัญญา (เดือน)"
        required
        sx={{ gridColumn: "3/span 2" }}
      />
      <FormNumericTextField
        name="price"
        control={control}
        label="ราคาต่อเดือน (บาท)"
        required
      />
      <FormNumericTextField
        name="discount"
        control={control}
        label="ราคาส่วนลด (บาท)"
      />
      <NumericTextField
        value={total}
        label="ราคารวม (บาท)"
        disabled
        sx={{ gridColumn: "3/span 2" }}
      />
      <FormDatePicker
        label="วันที่เริ่มใช้งาน"
        name="dateRange.start"
        control={control}
        TextFieldProps={{ required: true }}
      />
      <FormDatePicker
        label="วันสิ้นสุด"
        name="dateRange.end"
        control={control}
        TextFieldProps={{ required: true }}
      />
    </Section>
  );
}

export function useFitnessTransactionEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  plan: FitnessPlanAutocomplete.schema.label("Subscription Plan").required(),
  duration: yup.number().label("ระยะเวลาสัญญา (เดือน)").min(1).required(),
  price: yup.number().label("ราคาต่อเดือน (บาท)").min(0),
  discount: yup.number().label("ราคาส่วนลด (บาท)").min(0),
  dateRange: yup.object({
    start: datetime().label("วันที่เริ่มใช้งาน").required(),
    end: datetime().label("วันที่สิ้นสุด").required(),
  }),
});

const resolver = yupResolver(schema);

const defaultValues = { duration: 12 } as FitnessTransactionEditorState;
