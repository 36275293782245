import { useSnackbar } from "notistack";
import { useRef } from "react";
import { Stack } from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import ImageIcon from "@mui/icons-material/Image";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import LinkOffIcon from "@mui/icons-material/LinkOff";

import { upload } from "@/lib/firebase/storage";

import { FontFamilySelect } from "./FontFamilySelect";
import { RichTextColorPicker } from "./RichTextColorPicker";
import { HeadingSelect } from "./HeadingSelect";
import { YoutubeMenu } from "./YoutubeMenu";
import { MenuButton } from "./MenuButton";

import type { Editor } from "@tiptap/react";

type RichTextMenuProps = { editor: Editor | null };

export function RichTextMenu({ editor }: RichTextMenuProps) {
  const { enqueueSnackbar } = useSnackbar();
  const fileInput = useRef<HTMLInputElement>(null);

  async function uploadFile(file: File | null): Promise<void> {
    if (!file) return;
    const url = await upload("/blog/image", file);
    if (url && editor) {
      editor.chain().focus().setImage({ src: url }).run();
      if (fileInput.current) {
        fileInput.current.value = "";
      }
      return;
    }
    enqueueSnackbar("Unable to upload image.", { variant: "error" });
  }

  const handleFileUpload = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const executeAlignMent = (align: "left" | "center" | "right") => {
    if (editor) {
      editor.chain().focus().setTextAlign(align).run();
    }
  };
  const executeBold = () => {
    if (editor) {
      editor.chain().focus().toggleBold().run();
    }
  };

  const executeItalic = () => {
    if (editor) {
      editor.chain().focus().toggleItalic().run();
    }
  };

  const executeUnderline = () => {
    if (editor) {
      editor.chain().focus().toggleUnderline().run();
    }
  };

  const executeOrderList = () => {
    if (editor) {
      editor.chain().focus().toggleBulletList().run();
    }
  };

  const executeNumberList = () => {
    if (editor) {
      editor.chain().focus().toggleOrderedList().run();
    }
  };

  const executeStrike = () => {
    if (editor) {
      editor.chain().focus().toggleStrike().run();
    }
  };

  const executeUndo = () => {
    if (editor) {
      editor.chain().focus().undo().run();
    }
  };

  const executeRedo = () => {
    if (editor) {
      editor.chain().focus().redo().run();
    }
  };

  const executeSetLink = () => {
    const previousUrl =
      (editor?.getAttributes("link").href as string | undefined) ?? "";
    const url = window.prompt("URL", previousUrl);

    if (url === null || !editor) return;

    url === ""
      ? editor.chain().focus().extendMarkRange("link").unsetLink().run()
      : editor
          .chain()
          .focus()
          .extendMarkRange("link")
          .setLink({ href: url })
          .run();
  };

  const executeUnlink = () => {
    if (editor) {
      editor.chain().focus().unsetLink().run();
    }
  };

  return (
    <Stack border="1px solid #9E9E9E3D" gap={1} p={2} borderRadius={1}>
      <input
        style={{ display: "none" }}
        type="file"
        ref={fileInput}
        onChange={(event) => uploadFile(event.target.files?.[0] ?? null)}
      />
      <Stack direction="row" gap={1}>
        <MenuButton
          onClick={executeBold}
          isActive={editor?.isActive("bold")}
          Icon={FormatBoldIcon}
        />
        <MenuButton
          onClick={executeItalic}
          isActive={editor?.isActive("italic")}
          Icon={FormatItalicIcon}
        />
        <MenuButton
          onClick={executeUnderline}
          isActive={editor?.isActive("underline")}
          Icon={FormatUnderlinedIcon}
        />
        <MenuButton
          onClick={executeStrike}
          isActive={editor?.isActive("strike")}
          Icon={StrikethroughSIcon}
        />
        <FontFamilySelect editor={editor} />
        <HeadingSelect editor={editor} />
        <RichTextColorPicker editor={editor} />
        <MenuButton
          onClick={executeOrderList}
          isActive={editor?.isActive("bulletList")}
          Icon={FormatListBulletedIcon}
        />
        <MenuButton
          onClick={executeNumberList}
          isActive={editor?.isActive("orderedList")}
          Icon={FormatListNumberedIcon}
        />
      </Stack>
      <Stack direction="row" gap={1}>
        <MenuButton
          onClick={() => executeAlignMent("left")}
          isActive={editor?.isActive({ textAlign: "left" })}
          Icon={FormatAlignLeftIcon}
        />
        <MenuButton
          onClick={() => executeAlignMent("center")}
          isActive={editor?.isActive({ textAlign: "center" })}
          Icon={FormatAlignCenterIcon}
        />
        <MenuButton
          onClick={() => executeAlignMent("right")}
          isActive={editor?.isActive({ textAlign: "right" })}
          Icon={FormatAlignRightIcon}
        />
        <MenuButton onClick={executeSetLink} Icon={InsertLinkIcon} />
        <MenuButton
          onClick={executeUnlink}
          disabled={!editor?.isActive("link")}
          Icon={LinkOffIcon}
        />
        <MenuButton onClick={handleFileUpload} Icon={ImageIcon} />
        <YoutubeMenu editor={editor} />
        <MenuButton
          onClick={executeUndo}
          Icon={UndoIcon}
          disabled={!editor?.can().undo()}
        />
        <MenuButton
          onClick={executeRedo}
          Icon={RedoIcon}
          disabled={!editor?.can().redo()}
        />
      </Stack>
    </Stack>
  );
}
