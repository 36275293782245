import { api } from "@/client/api";
import { Branch } from "@/models";
import { configs } from "@/configs";

type CreateBranchInput = Omit<Branch, "id" | "code"> & {
  code: string;
  fitnessCenterId: number;
};

type UpdateBranchInput = Partial<Branch> & { id: number };

export async function getBranches(fitnessCenterId: string) {
  const { data } = await api.get<Branch[]>(
    `${configs.apiUrl}/commander/branches?fitnessCenterId=${fitnessCenterId}`
  );
  return data;
}

export async function getBranch(id: string): Promise<Branch> {
  const response = await api.get(`${configs.apiUrl}/commander/branches/${id}`);
  return response.data as Branch;
}

export async function createBranch(data: CreateBranchInput): Promise<Branch> {
  const response = await api.post(`${configs.apiUrl}/commander/branches`, data);
  return response.data as Branch;
}

export async function updateBranch({
  id,
  ...data
}: UpdateBranchInput): Promise<Branch> {
  const response = await api.patch(
    `${configs.apiUrl}/commander/branches/${id}`,
    data
  );
  return response.data as Branch;
}
