import {
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { TextEllipsis } from "@/components/TextEllipsis";
import { calculateColumnWidth } from "@/utils";
import {
  formatBlogStatus,
  formatBlogStatusColor,
  formatDate,
} from "@/formatter";
import { BlogStatus } from "@/models";

import type { MouseEvent, MouseEventHandler } from "react";
import type { Blog } from "@/models";

const columnsWidth = [140, 420, 560, 128, 96].map((item) =>
  calculateColumnWidth(item)
);

export type BlogTableRowProps = {
  data: Blog;
  fetchKeys?: string[];
};

export function BlogTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>วันที่สร้าง</TableCell>
      <TableCell width={columnsWidth[1]}>หัวข้อ</TableCell>
      <TableCell width={columnsWidth[2]}>รายละเอียดย่อ</TableCell>
      <TableCell width={columnsWidth[3]}>สถานะ</TableCell>
      <TableCell width={columnsWidth[4]} />
    </TableRow>
  );
}

export function BlogTableRow({ data }: BlogTableRowProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { createdAt, titleTH, shortDescriptionTH, publishedAt } = data;
  const id = data.id.toString();

  const status = publishedAt ? BlogStatus.Published : BlogStatus.Draft;

  function setDialog(event: MouseEvent, dialogName: string) {
    event.stopPropagation();
    searchParams.set("dialog", dialogName);
    searchParams.set("id", id);
    setSearchParams(searchParams);
  }
  const onClickEdit: MouseEventHandler<HTMLButtonElement> = (event) => {
    setDialog(event, "blog-edit");
  };

  const onClickRemove: MouseEventHandler<HTMLButtonElement> = (event) => {
    setDialog(event, "blog-remove");
  };

  const onClickTableRow: MouseEventHandler<HTMLTableRowElement> = () => {
    navigate(id);
  };

  return (
    <TableRow hover tabIndex={-1} role="link" onClick={onClickTableRow}>
      <TableCell>{formatDate(createdAt)}</TableCell>
      <TableCell>{titleTH}</TableCell>
      <TableCell>
        <TextEllipsis variant="body2" line={2}>
          {shortDescriptionTH}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <Stack direction="row" gap={1} alignItems="center">
          <CircleIcon
            sx={{
              width: 12,
              height: 12,
              color: formatBlogStatusColor(status),
            }}
          />
          <Typography variant="body2">{formatBlogStatus(status)}</Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack direction="row" gap={1}>
          <Tooltip title="แก้ไข">
            <IconButton onClick={onClickEdit}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="ลบ">
            <IconButton onClick={onClickRemove}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </TableCell>
    </TableRow>
  );
}
