import { api } from "@/client/api";
import { Blog } from "@/models";
import { configs } from "@/configs";
import { parseDateTimeFromPrisma } from "@/utils";

type GetBlogs = {
  data: Blog[];
  total: number;
};

type CreateBlogInput = Omit<Blog, "id" | "createdAt" | "publishedAt">;
type UpdateBlogInput = Partial<Blog> & { id: number };

type PublishBlogInput = {
  id: string;
  isPublished: boolean;
};

export async function getBlogs(query = "") {
  const { data } = await api.get<GetBlogs>(
    `${configs.apiUrl}/commander/blogs?${query}`
  );
  return parseDateTimeFromPrisma(data) as GetBlogs;
}

export async function getBlog(id: string) {
  const { data } = await api.get<Blog>(
    `${configs.apiUrl}/commander/blogs/${id}`
  );
  return parseDateTimeFromPrisma(data) as Blog;
}

export async function createBlog(data: CreateBlogInput): Promise<Blog> {
  const response = await api.post(`${configs.apiUrl}/commander/blogs`, data);
  return response.data as Blog;
}

export async function removeBlog(id: string): Promise<void> {
  await api.delete(`${configs.apiUrl}/commander/blogs/${id}`);
}

export async function updateBlog({
  id,
  ...data
}: UpdateBlogInput): Promise<Blog> {
  const response = await api.patch(
    `${configs.apiUrl}/commander/blogs/${id}`,
    data
  );
  return response.data as Blog;
}

export async function publishBlog(input: PublishBlogInput): Promise<void> {
  const { id, isPublished } = input;
  await api.patch(`${configs.apiUrl}/commander/blogs/${id}/publish`, {
    isPublished,
  });
}
