import {
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { refetchQueries } from "@/utils";
import { configs } from "@/configs";
import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { FormSwitch } from "@/components/Switch";
import { FormCheckbox } from "@/components/FormCheckbox";
import { updateFitnessSetting } from "@/services/fitness";

import type { FitnessCenter } from "@/models";
import type { AxiosErrorWithData } from "@/client/api";
import type { InferType } from "yup";

export type FitnessSettingEditorState = InferType<typeof schema>;

export type FitnessSettingDialogProps = {
  open: boolean;
  data: FitnessCenter;
  onClose: () => void;
  fetchKeys?: string[];
};

export function FitnessSettingDialog({
  open: isOpen,
  onClose,
  data,
  fetchKeys = [],
}: FitnessSettingDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useFitnessSettingEditorForm();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isOpen && data) {
      const { hasPermissionGroupControl, branches } = data;

      reset({ hasPermissionGroupControl, branches });
    }
  }, [isOpen, data, reset]);

  const sortedBranches = data.branches.sort((a, b) => a.id - b.id);

  const { mutate: update, isLoading } = useMutation(updateFitnessSetting, {
    onSuccess: async () => {
      enqueueSnackbar("ตั้งค่าฟิตเนสสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      onClose();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  const onSubmit = handleSubmit((form) => {
    update({ id: data.id, ...form });
  });

  const title = "การตั้งค่าฟิตเนส";

  return (
    <FormConfirmDialog
      control={control}
      title={title}
      loading={isLoading}
      open={isOpen}
      onClose={onClose}
      onConfirm={onSubmit}
    >
      <Stack gap={5}>
        <Stack direction="row" gap={7} alignItems="center">
          <Typography variant="subtitle1">การตั้งค่าสิทธิ์การใช้งาน</Typography>
          <FormSwitch
            name="hasPermissionGroupControl"
            control={control}
            label="สิทธิ์การใช้งาน"
          />
        </Stack>
        <Divider />
        <Stack gap={2}>
          <Typography variant="subtitle1">การตั้งค่าประตู</Typography>
          <Typography variant="body1" color="text.secondary">
            เลือกสาขาที่ต้องการใช้งานการตั้งค่าประตู
          </Typography>
          <FormGroup row>
            {sortedBranches.map(({ id, name }, index) => (
              <FormControlLabel
                key={id}
                control={
                  <FormCheckbox
                    name={`branches.${index}.hasDoorAccessControl`}
                    control={control}
                  />
                }
                label={name}
              />
            ))}
          </FormGroup>
        </Stack>
      </Stack>
    </FormConfirmDialog>
  );
}

const schema = yup.object({
  hasPermissionGroupControl: yup.boolean().required(),
  branches: yup
    .array(
      yup.object({
        id: yup.number().required(),
        name: yup.string().required(),
        hasDoorAccessControl: yup.boolean().required(),
      })
    )
    .required(),
});

export function useFitnessSettingEditorForm() {
  const resolver = yupResolver(schema);

  return useForm({
    resolver,
    defaultValues,
  });
}

const defaultValues = {
  hasPermissionGroupControl: false,
  branches: [],
} as FitnessSettingEditorState;
