import { Button } from "@mui/material";

import type { SvgIconTypeMap } from "@mui/material";
import type { OverridableComponent } from "@mui/material/OverridableComponent";

type MenuButtonProps = {
  onClick: () => void;
  Icon: OverridableComponent<SvgIconTypeMap<unknown, "svg">>;
  isActive?: boolean;
  disabled?: boolean;
};

export function MenuButton({
  onClick,
  Icon,
  isActive = false,
  disabled = false,
}: MenuButtonProps) {
  const bgcolor = isActive ? "grey.400" : "unset";

  return (
    <Button
      disableTouchRipple
      sx={{
        bgcolor,
        borderRadius: 0,
        border: "1px solid black",
        ":hover": { bgcolor },
        ":disabled": {
          borderColor: "grey.100",
        },
        height: 56,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon sx={{ color: disabled ? "grey.400" : "text.secondary" }} />
    </Button>
  );
}
