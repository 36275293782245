import { useState } from "react";
import { FormHelperText, Stack } from "@mui/material";
import { useEditor, EditorContent } from "@tiptap/react";
import { useController } from "react-hook-form";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import Image from "@tiptap/extension-image";
import Placeholder from "@tiptap/extension-placeholder";
import Link from "@tiptap/extension-link";
import TextStyle from "@tiptap/extension-text-style";
import FontFamily from "@tiptap/extension-font-family";
import Color from "@tiptap/extension-color";
import Youtube from "@tiptap/extension-youtube";

import { RichTextMenu } from "@/components/RichText/RichTextMenu";
import { theme } from "@/theme";

import type { ControllerProps, FieldPath, FieldValues } from "react-hook-form";

interface RichTextProps {
  placeholder?: string;
  value: string;
  errorMessage?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  onChange: (value: string) => void;
}

export function RichText({
  placeholder = "",
  value,
  onChange,
  onBlur,
  onFocus,
  errorMessage,
}: RichTextProps) {
  const [isEmpty, setIsEmpty] = useState(!value);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Image,
      TextStyle,
      FontFamily,
      Color,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder }),
      Link.configure({ openOnClick: false }),
      Youtube.configure({ controls: false }),
    ],
    content: value,
    onUpdate: ({ editor }) => {
      const newHtml = editor.getHTML();
      const empty = newHtml === "<p></p>";
      setIsEmpty(empty);
      onChange(empty ? "" : newHtml);
    },
    onFocus: () => {
      onFocus && onFocus();
    },
    onBlur: () => {
      onBlur && onBlur();
      if (!isEmpty) return;
    },
  });

  const errorStyle = errorMessage
    ? {
        border: "1px solid",
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.error.main,
      }
    : {};

  return (
    <Stack gap={2}>
      <RichTextMenu editor={editor} />
      <Stack>
        <EditorContent editor={editor} style={errorStyle} />
        <FormHelperText error={!!errorMessage} sx={{ ml: "14px", mb: 3 }}>
          {errorMessage}
        </FormHelperText>
      </Stack>
    </Stack>
  );
}

export function FormRichText<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  onChange,
  ...props
}: Pick<ControllerProps<TFieldValues, TName>, "name" | "control"> &
  Partial<Omit<RichTextProps, "value" | "onBlur" | "errorMessage">>) {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <RichText
      value={field.value ?? ""}
      onChange={(data) => {
        field.onChange(data);
        onChange?.(data);
      }}
      onBlur={field.onBlur}
      errorMessage={fieldState.error?.message}
      {...props}
    />
  );
}
