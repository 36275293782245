import { Box, Tab, Tabs } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import type { TabsProps } from "@mui/material";

export function FitnessListPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location?.pathname ?? "/fitnesses";

  const onChangeTab: TabsProps["onChange"] = (_, value: string) => {
    navigate(value, { replace: true });
  };

  return (
    <Box>
      <Tabs value={path} sx={{ mb: 2 }} onChange={onChangeTab}>
        <Tab label="ใช้งานอยู่" sx={{ width: 180 }} value="/fitnesses" />
        <Tab label="หมดอายุ" sx={{ width: 180 }} value="/fitnesses/expired" />
      </Tabs>
      <Outlet />
    </Box>
  );
}
