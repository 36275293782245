import {
  Avatar,
  Box,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  Stack,
  List,
  Menu,
  Divider,
  Button,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  signOut,
  useAuthentication,
  useCurrentUser,
} from "@/features/authentication/contexts/AuthenticationContext";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import LogoHorizontal from "@/assets/logo-commander-horizontal.svg";

import type { ListItemButtonProps } from "@mui/material";

export function AppBar() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const menu = [
    { label: "ภาพรวมโดยสังเขป", path: "dashboard" },
    { label: "รายชื่อฟิตเนส", path: "fitnesses" },
    { label: "SUBSCRIPTION PACKAGE", path: "packages" },
    { label: "บทความ", path: "blogs" },
  ];

  const onClickLogo = () => navigate("/check-in");

  return (
    <Stack minWidth={1024}>
      <Paper
        elevation={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          p: 3,
          borderRadius: 0,
          gap: 2,
          position: "fixed",
          top: 0,
          zIndex: 100,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            component="img"
            src={LogoHorizontal}
            alt="logo-horizontal"
            width={120}
            height={64}
            sx={{ cursor: "pointer" }}
            onClick={onClickLogo}
          />
          <Stack direction="row" gap={3}>
            <UserAvatarButton />
          </Stack>
        </Stack>
        <Divider />
        <Stack direction="row" gap={3}>
          {menu.map(({ label, path }) => (
            <Button
              key={label}
              variant="text"
              size="medium"
              onClick={() => navigate(`/${path}`)}
              sx={{
                color: pathname.split("/")[1].includes(path)
                  ? "primary.main"
                  : "text.primary",
              }}
            >
              {label}
            </Button>
          ))}
        </Stack>
      </Paper>
      <Box mt={24} p={3} mx="auto" width="100%" maxWidth={1440}>
        <Outlet />
      </Box>
    </Stack>
  );
}

function UserAvatarButton() {
  const { email } = useCurrentUser();

  const [, dispatch] = useAuthentication();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const confirmDialog = {
    maxWidth: "xs" as const,
    open: openConfirmDialog,
    onClose: () => setOpenConfirmDialog(false),
    onConfirm: async () => {
      await signOut(dispatch, queryClient);
    },
    title: "คุณต้องการออกจากระบบหรือไม่",
    confirmMessage: "ออกจากระบบ",
  };

  const open = Boolean(anchorEl);

  const onClickAvatar: ListItemButtonProps["onClick"] = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClickSignOut = () => {
    setAnchorEl(null);
    setOpenConfirmDialog(true);
  };

  const onClose = () => setAnchorEl(null);

  return (
    <List sx={{ p: 0 }}>
      <ListItemButton sx={{ p: 0 }} onClick={onClickAvatar}>
        <ListItemAvatar>
          <Avatar alt={email} src={email}>
            {email[0].toUpperCase()}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{
            noWrap: true,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          primary={email}
          sx={{ width: 152 }}
        />
        <IconButton>
          <ExpandMoreIcon />
        </IconButton>
      </ListItemButton>
      <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
        <MenuItem sx={{ width: 256 }} onClick={onClickSignOut}>
          ออกจากระบบ
        </MenuItem>
      </Menu>
      <ConfirmDialog {...confirmDialog} />
    </List>
  );
}
