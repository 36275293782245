import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";

import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { createFitnessTransaction } from "@/services/fitnessTransation";
import { refetchQueries } from "@/utils";
import { configs } from "@/configs";

import {
  FitnessTransactionEditor,
  useFitnessTransactionEditorForm,
} from "../../components/FitnessTransactionEditor";

import type { AxiosErrorWithData } from "@/client/api";

type SubscribeDialogProps = {
  fitnessCenterId: string;
  open: boolean;
  onClose: () => void;
  fetchKeys?: string[];
};

export function SubscribeDialog({
  fitnessCenterId,
  open: isOpen,
  onClose,
  fetchKeys = [],
}: SubscribeDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useFitnessTransactionEditorForm();
  const queryClient = useQueryClient();

  const { mutate: subscribe, isLoading } = useMutation(
    createFitnessTransaction,
    {
      onSuccess: async () => {
        enqueueSnackbar("บันทึกสำเร็จ", { variant: "success" });
        await refetchQueries({ queryClient, fetchKeys });
        close();
      },
      onError: (error: AxiosErrorWithData) => {
        console.error(error);
        enqueueSnackbar(
          error.response?.data.message ?? configs.unknownErrorMessage,
          { variant: "error" }
        );
      },
    }
  );

  const title = "Subscription";

  function close() {
    reset();
    onClose();
  }

  const onSubmit = handleSubmit((form) => {
    subscribe({
      fitnessCenterId: +fitnessCenterId,
      planId: form.plan.id,
      price: form.price ?? 0,
      discount: form.discount ?? 0,
      startedAt: form.dateRange.start,
      expiredAt: form.dateRange.end,
      duration: form.duration,
    });
  });

  return (
    <FormConfirmDialog
      control={control}
      title={title}
      loading={isLoading}
      open={isOpen}
      onClose={onClose}
      onConfirm={onSubmit}
    >
      <FitnessTransactionEditor control={control} />
    </FormConfirmDialog>
  );
}
