import { matchIsValidColor, MuiColorInput } from "mui-color-input";
import {
  Controller,
  ControllerProps,
  useForm,
  useWatch,
} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography } from "@mui/material";

import { FormTextField } from "@/components/FormTextField";
import { FormImageUploader } from "@/components/ImageUploader";
import { configs } from "@/configs";

import { MemberCard } from "./MemberCard";

export type MemberCardEditorState = yup.InferType<typeof schema>;

type MemberCardEditorProps = Pick<
  ControllerProps<MemberCardEditorState>,
  "control"
> & { fitnessCenterId: number };

export function MemberCardEditor({
  control,
  fitnessCenterId,
}: MemberCardEditorProps) {
  const formValues = useWatch({ control });

  const cardData = {
    textColor: formValues.textColor ?? "",
    backgroundColor: formValues.backgroundColor ?? "",
    logoUrl: formValues.logoImg?.at(0)?.url ?? "",
    backgroundUrl: formValues.backgroundImg?.at(0)?.url ?? "",
    title: formValues.title ?? "",
  };

  return (
    <Stack direction="row" gap={5}>
      <MemberCard data={cardData} />
      <Stack flexGrow={1} gap={5}>
        <Stack gap={2.5}>
          <Typography variant="labelLarge">ข้อมูลบัตร</Typography>
          <FormTextField
            control={control}
            name="title"
            label="ชื่อบัตร"
            required
          />
          <FormTextField
            control={control}
            name="description"
            label="รายละเอียด"
          />
        </Stack>
        <Stack direction="row" gap={9}>
          <Stack gap={2.5}>
            <Typography>สีตัวอักษร</Typography>
            <Controller
              name="textColor"
              control={control}
              rules={{ validate: matchIsValidColor }}
              render={({ field, fieldState }) => (
                <MuiColorInput
                  {...field}
                  format="hex"
                  helperText={fieldState.error ? "Color is invalid" : ""}
                  error={!!fieldState.error}
                  inputProps={{ sx: { width: 82 }, maxLength: 7 }}
                />
              )}
            />
          </Stack>
          <Stack gap={2.5}>
            <Typography>สีพื้นหลัง</Typography>
            <Controller
              name="backgroundColor"
              control={control}
              rules={{ validate: matchIsValidColor }}
              render={({ field, fieldState }) => (
                <MuiColorInput
                  {...field}
                  format="hex"
                  helperText={fieldState.error ? "Color is invalid" : ""}
                  error={!!fieldState.error}
                  inputProps={{ sx: { width: 82 }, maxLength: 7 }}
                />
              )}
            />
          </Stack>
        </Stack>
        <Stack>
          <FormImageUploader
            fitnessCenterId={fitnessCenterId}
            title="อัพโหลดโลโก้*"
            control={control}
            module="cards"
            name="logoImg"
            style={{ titleHelperTextFlexDirection: "column" }}
            aspect={configs.aspectRatio.memberCardLogo}
          />
          <FormImageUploader
            fitnessCenterId={fitnessCenterId}
            title="อัพโหลดภาพพื้นหลัง"
            control={control}
            module="cards"
            name="backgroundImg"
            style={{ titleHelperTextFlexDirection: "column" }}
            aspect={configs.aspectRatio.memberCardBg}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

export function useMemberCardEditorForm() {
  return useForm({ resolver, defaultValues: {} as MemberCardEditorState });
}

const schema = yup.object({
  title: yup.string().required("ระบุชื่อบัตร"),
  description: yup.string(),
  textColor: yup.string().required(),
  backgroundColor: yup.string().required(),
  logoImg: FormImageUploader.schema.label("โลโก้").min(1),
  backgroundImg: FormImageUploader.schema,
});

const resolver = yupResolver(schema);
