import { TableCell, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { TextEllipsis } from "@/components/TextEllipsis";
import { formatDate } from "@/formatter";
import { calculateColumnWidth, mapOptional } from "@/utils";

import type { FitnessCenter } from "@/models";
import type { MouseEventHandler } from "react";

const columnsWidth = [704, 320, 320].map((item) => calculateColumnWidth(item));

export type ExpiredTableRowProps = {
  data: FitnessCenter;
};

export function ExpiredTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>ชื่อฟิตเนส</TableCell>
      <TableCell width={columnsWidth[1]} align="center">
        Package ล่าสุด
      </TableCell>
      <TableCell width={columnsWidth[2]} align="center">
        วันที่หมดอายุล่าสุด
      </TableCell>
    </TableRow>
  );
}

export function ExpiredTableRow({ data }: ExpiredTableRowProps) {
  const navigate = useNavigate();

  const { id, name, activePlan } = data;

  const onClickTableRow: MouseEventHandler<HTMLTableRowElement> = () => {
    navigate(id.toString());
  };

  return (
    <TableRow hover tabIndex={-1} role="link" onClick={onClickTableRow}>
      <TableCell>
        <TextEllipsis variant="body2">{name}</TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2" align="center">
          {activePlan?.plan?.name ?? "-"}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2" align="center">
          {mapOptional(activePlan?.expiredAt, formatDate) ?? "-"}
        </TextEllipsis>
      </TableCell>
    </TableRow>
  );
}
